import React, { useState, } from 'react';
import { useTelegram } from '../../../Hooks/useTelegram';
import styles from './style/TelegramSettings.module.css';

interface Settings {
    autoPosting: boolean;
    notificationsEnabled: boolean;
    defaultChannel?: string;
    postingInterval: number;
}

export const TelegramBaseSettings: React.FC = () => {
    const { channels, isLoading, error } = useTelegram();
    const [settings, setSettings] = useState<Settings>({
        autoPosting: false,
        notificationsEnabled: true,
        postingInterval: 60,
    });

    const handleSettingChange = (
        setting: keyof Settings,
        value: string | boolean | number
    ) => {
        setSettings(prev => ({
            ...prev,
            [setting]: value
        }));
    };

    const handleSaveSettings = async () => {
        try {
            // Тут буде запит до API для збереження налаштувань
            console.log('Saving settings:', settings);
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    return (
        <div className={styles.settingsContainer}>
            <h2 className={styles.settingsTitle}>Налаштування Telegram</h2>

            <div className={styles.settingsGroup}>
                <h3>Основні налаштування</h3>
                
                <div className={styles.settingItem}>
                    <label className={styles.settingLabel}>
                        <input
                            type="checkbox"
                            checked={settings.autoPosting}
                            onChange={(e) => handleSettingChange('autoPosting', e.target.checked)}
                        />
                        Автоматична публікація постів
                    </label>
                </div>

                <div className={styles.settingItem}>
                    <label className={styles.settingLabel}>
                        <input
                            type="checkbox"
                            checked={settings.notificationsEnabled}
                            onChange={(e) => handleSettingChange('notificationsEnabled', e.target.checked)}
                        />
                        Сповіщення про публікації
                    </label>
                </div>

                <div className={styles.settingItem}>
                    <label className={styles.settingLabel}>
                        Канал за замовчуванням
                    </label>
                    <select
                        value={settings.defaultChannel}
                        onChange={(e) => handleSettingChange('defaultChannel', e.target.value)}
                        className={styles.settingSelect}
                    >
                        <option value="">Виберіть канал</option>
                        {channels.map(channel => (
                            <option key={channel.id} value={channel.id}>
                                {channel.title}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.settingItem}>
                    <label className={styles.settingLabel}>
                        Інтервал між постами (хвилини)
                    </label>
                    <input
                        type="number"
                        value={settings.postingInterval}
                        onChange={(e) => handleSettingChange('postingInterval', parseInt(e.target.value))}
                        min="1"
                        className={styles.settingInput}
                    />
                </div>
            </div>

            <div className={styles.settingsActions}>
                <button
                    className={styles.saveButton}
                    onClick={handleSaveSettings}
                >
                    Зберегти налаштування
                </button>
            </div>
        </div>
    );
};