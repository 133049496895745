import React, { useState } from "react";
import ModalCustom from "../../partial/ModalCustom";
import {
  commandsExample,
  getHandlerForCommand,
} from "../constants/botConstructorHints";
import { BotData } from "../types/telegram";

interface CommandManagerProps {
  bot: BotData;
  setBot: (bot: BotData) => void;
  onSave: (botData: BotData) => Promise<void>;
  isOpenCommandsHint: boolean;
  setIsOpenCommandsHint: (isOpen: boolean) => void;
  setMessage: (message: string) => void;
  addCommand: (
    botId: number,
    command: string,
    response: string,
    handlerName?: string
  ) => Promise<void>;
  saveHandler: (botId: number, handlerData: any) => Promise<void>;
}

const CommandManager: React.FC<CommandManagerProps> = ({
  bot,
  setBot,
  onSave,
  isOpenCommandsHint,
  setIsOpenCommandsHint,
  setMessage,
  addCommand,
  saveHandler,
}) => {
  const [newCommand, setNewCommand] = useState({
    command: "",
    response: "",
    handler_name: null,
  });

  const handleAddCommand = async () => {
    if (!newCommand.command || !newCommand.response) {
      setMessage("Введіть команду та відповідь");
      return;
    }

    if (!bot.id) {
      setMessage("Спочатку збережіть основні налаштування бота");
      return;
    }

    try {
      // Перевіряємо чи це не response від обробника
      const isHandler =
        newCommand.response.startsWith("[Handler: ") &&
        newCommand.response.endsWith("]");
      const handlerName =
        newCommand.handler_name ||
        (isHandler
          ? newCommand.response.replace("[Handler: ", "").replace("]", "")
          : null);

      await addCommand(
        bot.id,
        newCommand.command,
        newCommand.response,
        handlerName
      );

      // Оновлюємо локальний стан
      setBot({
        ...bot,
        commands: {
          ...bot.commands,
          [newCommand.command]: {
            response: newCommand.response,
            created_at: new Date().toISOString(),
            ...(handlerName && { handler_name: handlerName }),
          },
        },
      });

      setNewCommand({ command: "", response: "", handler_name: "" });
    } catch (error) {
      setMessage(
        `Помилка додавання команди: ${
          error instanceof Error ? error.message : "Невідома помилка"
        }`
      );
    }
  };

  const removeCommand = async (command: string) => {
    const newCommands = { ...bot.commands };
    delete newCommands[command];

    setBot({
      ...bot,
      commands: newCommands,
    });
    await onSave({
      ...bot,
      commands: newCommands,
    });
  };

  const loadExampleCommand = (command: string) => {
    const commandData = commandsExample[command];
    if (!commandData) return;

    // Завантажуємо приклад у форму для редагування
    setNewCommand({
      command,
      response: commandData.response,
      handler_name: commandData.handler_name || "",
    });

    setMessage(`Приклад команди ${command} завантажено для редагування`);
  };

  const loadHandlerForCommand = async (command: string) => {
    const handler = getHandlerForCommand(command);
    if (handler) {
      try {
        // Спочатку створюємо обробник
        await saveHandler(bot.id, {
          name: handler.name,
          code: handler.code,
          description: handler.description,
        });

        // Додаємо команду з прив'язкою до обробника
        await addCommand(bot.id, command, "", handler.name); // Змінюємо тут
        setIsOpenCommandsHint(false);
      } catch (error) {
        setMessage("Обробник для цієї команди не знайдено.");
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Команди</h3>
        <button
          onClick={() => setIsOpenCommandsHint(true)}
          className="text-blue-500 hover:text-blue-600"
        >
          Приклади команд
        </button>
      </div>

      {/* Форма додавання нової команди */}
      <div className="bg-gray-50 p-4 rounded-lg space-y-2">
        <div className="flex gap-2">
          <input
            type="text"
            value={newCommand.command}
            onChange={(e) =>
              setNewCommand({ ...newCommand, command: e.target.value })
            }
            className="flex-1 p-2 border rounded"
            placeholder="/команда"
          />
          <input
            type="text"
            value={newCommand.response}
            onChange={(e) =>
              setNewCommand({ ...newCommand, response: e.target.value })
            }
            className="flex-1 p-2 border rounded"
            placeholder="Відповідь на команду"
          />
          <input
            type="text"
            value={newCommand.handler_name}
            onChange={(e) =>
              setNewCommand({ ...newCommand, handler_name: e.target.value })
            }
            className="flex-1 p-2 border rounded"
            placeholder="Назва обробника для команди"
          />
          <button
            onClick={handleAddCommand}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Додати
          </button>
        </div>
      </div>

      {/* Список існуючих команд */}
      <div className="space-y-2">
        {Object.entries(bot.commands || {}).map(([command, data]) => (
          <div key={command} className="flex items-center space-x-2">
            <input
              type="text"
              value={command}
              readOnly
              className="w-1/3 p-2 border rounded bg-gray-50"
            />
            <input
              type="text"
              value={data.response}
              readOnly
              className="flex-1 p-2 border rounded bg-gray-50"
            />
            <input
              type="text"
              value={data.handler_name || ""}
              readOnly
              className="flex-1 p-2 border rounded bg-gray-50"
            />
            <button
              onClick={() => removeCommand(command)}
              className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
            >
              Видалити
            </button>
          </div>
        ))}
      </div>

      {/* Модальне вікно з прикладами */}
      {isOpenCommandsHint && (
        <ModalCustom
          isOpen={isOpenCommandsHint}
          closeModal={() => setIsOpenCommandsHint(false)}
          text="Приклади команд"
          zIndex={10}
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-4">
              Приклади базових команд для бота:
            </h3>
            {Object.entries(commandsExample).map(([command, data]) => (
              <div key={command} className="mb-6 bg-gray-50 p-4 rounded-lg">
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      loadExampleCommand(command);
                      setIsOpenCommandsHint(false);
                    }}
                    className="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    Використати команду
                  </button>
                  {getHandlerForCommand(command) && (
                    <button
                      onClick={() => loadHandlerForCommand(command)}
                      className="text-sm bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                    >
                      Використати з обробником
                    </button>
                  )}
                </div>
                <div className="bg-white p-3 rounded border mb-2 whitespace-pre-wrap font-mono text-sm">
                  {data.response}
                </div>
                <div className="text-gray-600 text-sm">
                  <strong>Опис:</strong> {data.description}
                </div>
              </div>
            ))}
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default CommandManager;
