// components/TelegramAuth/PhoneStep.tsx
import React, { useState } from 'react';
import styles from '../style/TelegramAuth.module.css';

interface PhoneStepProps {
  onSubmit: (phone: string) => Promise<void>;
  isLoading: boolean;
}

export const PhoneStep: React.FC<PhoneStepProps> = ({ onSubmit, isLoading }) => {
  const [phone, setPhone] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!phone) return;
      
      try {
          await onSubmit(phone);
      } catch (error) {
          console.log("error", error);
      }
  };

  return (
      <form onSubmit={handleSubmit} className={styles.stepForm}>
          <div className={styles.authHeader}>
              <h2 className={styles.authTitle}>Вхід в Telegram</h2>
              <p className={styles.authSubtitle}>
                  Введіть ваш номер телефону для авторизації через Telegram
              </p>
          </div>

          <div className={styles.formGroup}>
              <label className={styles.formLabel}>
                  Номер телефону
              </label>
              <input
                  type="tel"
                  className={styles.formInput}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="+380671234567"
                  required
                  pattern="\+?[0-9]{10,13}"
                  disabled={isLoading}
              />
          </div>

          <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isLoading || !phone}
          >
              {isLoading ? 'Відправка...' : 'Продовжити'}
          </button>
      </form>
  );
};