import React, { useEffect, useState } from "react";
import BotSettings from "./TelegramBotAPI/BotSettings";
import { BotData } from "./types/telegram";
import CommandManager from "./TelegramBotAPI/CommandManager";
import HandlerManager from "./TelegramBotAPI/HandlerManager";
import KeyboardManager from "./TelegramBotAPI/KeyboardManager";
import axios from "axios";
import MenuManager from "./TelegramBotAPI/MenuManager";
import StateManager from "./TelegramBotAPI/StateManager";

interface TelegramBotProps {
  projectId: string;
  setMessage: (message: string) => void;
}

const TelegramBotAPI: React.FC<TelegramBotProps> = ({
  projectId,
  setMessage,
}) => {
  const [bots, setBots] = useState<BotData[]>([]);
  const [selectedBot, setSelectedBot] = useState<BotData | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("settings");

  // Модальні вікна
  const [isOpenCommandsHint, setIsOpenCommandsHint] = useState(false);
  const [isOpenHandlersHint, setIsOpenHandlersHint] = useState(false);
  const [isOpenKeyboardHint, setIsOpenKeyboardHint] = useState(false);
  const [isOpenMenuHint, setIsOpenMenuHint] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Завантаження списку ботів
  const fetchBots = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/telegram-bots`, {
        params: { project_id: projectId },
        headers: { Authorization: `Bearer ${token}` },
      });

      setBots(response.data);
      if (!selectedBot && response.data.length > 0) {
        setSelectedBot(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching bots:", error);
      setMessage("Помилка завантаження ботів");
    } finally {
      setLoading(false);
    }
  };

  // Створення нового бота
  const createBot = async (botData: BotData) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots`,
        {
          ...botData,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const newBot = response.data;
      setBots([...bots, newBot]);
      setSelectedBot(newBot);
      setMessage("Бота успішно створено");
    } catch (error) {
      console.error("Error creating bot:", error);
      setMessage("Помилка створення бота");
    }
  };

  // Оновлення бота
  const updateBot = async (botData: BotData) => {
    if (!selectedBot?.id) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiUrl}/telegram/telegram-bots/${selectedBot.id}`,
        {
          ...botData,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const updatedBot = response.data;
      setBots(bots.map((bot) => (bot.id === updatedBot.id ? updatedBot : bot)));
      setSelectedBot(updatedBot);
      setMessage("Бота успішно оновлено");
      fetchBots();
    } catch (error) {
      console.error("Error updating bot:", error);
      setMessage("Помилка оновлення бота");
    }
  };

  // Додавання команди
  const addCommand = async (
    botId: number,
    command: string,
    response: string,
    handlerName?: string
  ) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${botId}/command`,
        {
          command,
          response,
          handler_name: handlerName,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage("Команду успішно додано");
      fetchBots();
    } catch (error) {
      console.error("Error adding command:", error);
      setMessage("Помилка додавання команди");
    }
  };

  // Оновлення клавіатури
  const updateKeyboard = async (botId: number, keyboardData: any) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${botId}/keyboard`,
        {
          ...keyboardData,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage("Клавіатуру успішно оновлено");
      fetchBots();
    } catch (error) {
      console.error("Error updating keyboard:", error);
      setMessage("Помилка оновлення клавіатури");
    }
  };

  // Робота з обробниками
  const fetchHandlers = async (botId: number) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/bots/${botId}/handlers`,
        {
          params: { project_id: projectId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching handlers:", error);
      setMessage("Помилка завантаження обробників");
      return null;
    }
  };

  const saveHandler = async (botId: number, handlerData: any) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/bots/${botId}/handlers`,
        {
          ...handlerData,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage("Обробник успішно збережено");
    } catch (error) {
      console.error("Error saving handler:", error);
      setMessage("Помилка збереження обробника");
    }
  };

  // Додаємо новий метод для оновлення
  const updateHandler = async (
    botId: number,
    handlerName: string,
    handlerData: any
  ) => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${apiUrl}/telegram/telegram-bots/bots/${botId}/handlers/${handlerName}`,
        {
          ...handlerData,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage("Обробник успішно оновлено");
    } catch (error) {
      console.error("Error updating handler:", error);
      setMessage("Помилка оновлення обробника");
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchBots();
    }
  }, [projectId]);

  // Створення нового пустого бота
  const handleCreateNewBot = () => {
    const newBot: BotData = {
      name: "",
      bot_token: "",
      description: null,
      welcome_message: "",
      error_message: "",
      commands: {},
      keyboard_settings: {
        type: "reply",
        buttons: [],
      },
      menu_structure: {
        items: [],
      },
      settings: {
        handlers: {}, // Ініціалізуємо пустий об'єкт для handlers
      },
      is_active: true,
      project_id: Number(projectId), // Конвертуємо string в number
      webhook_url: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    setSelectedBot(newBot);
    setActiveTab("settings");
  };

  const setWebhook = async (botId: number) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${botId}/webhook`,
        {
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Вебхук успішно встановлено");
      fetchBots(); // Оновлюємо список ботів щоб отримати оновлену інформацію
    } catch (error) {
      console.error("Error setting webhook:", error);
      setMessage("Помилка встановлення вебхука");
    }
  };

  const deleteBot = async (botId: number) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${apiUrl}/telegram/telegram-bots/${botId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setBots(bots.filter((bot) => bot.id !== botId));
      setSelectedBot(null);
      setMessage("Бота успішно видалено");
    } catch (error) {
      console.error("Error deleting bot:", error);
      setMessage("Помилка видалення бота");
    }
  };
  const deleteHandler = async (botId: number, handlerName: string) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${apiUrl}/telegram/telegram-bots/bots/${botId}/handlers/${handlerName}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Обробник видалено");
    } catch (error) {
      setMessage("Помилка видалення обробника");
    }
  };

  const deleteMenuItem = async (index: number) => {
    if (!selectedBot) return;

    const newMenu = {
      items: selectedBot.menu_structure.items.filter((_, i) => i !== index),
    };

    try {
      await updateBot({
        ...selectedBot,
        menu_structure: newMenu,
      });
      setMessage("Пункт меню видалено");
    } catch (error) {
      setMessage("Помилка видалення пункту меню");
    }
  };
  return (
    <div className="mb-8">
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Управління ботами</h2>
          <button
            onClick={handleCreateNewBot}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
          >
            + Створити нового бота
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {/* Список ботів */}
          {bots.length > 0 && (
            <div className="mb-4 flex space-x-2 overflow-x-auto pb-2">
              {bots.map((bot) => (
                <button
                  key={bot.id}
                  onClick={() => setSelectedBot(bot)}
                  className={`px-4 py-2 rounded whitespace-nowrap transition-colors ${
                    selectedBot?.id === bot.id
                      ? "bg-blue-500 text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                >
                  {bot.name || "Бот без назви"}
                </button>
              ))}
            </div>
          )}

          {/* Вкладки для вибраного бота */}
          {selectedBot && (
            <div className="bg-white rounded-lg shadow">
              <div className="border-b border-gray-200">
                <nav className="flex space-x-4">
                  {[
                    { id: "settings", label: "Налаштування" },
                    { id: "commands", label: "Команди" },
                    { id: "handlers", label: "Обробники" },
                    { id: "keyboard", label: "Клавіатура" },
                    { id: "menu", label: "Меню" },
                    { id: "states", label: "Стани" },
                  ].map(({ id, label }) => (
                    <button
                      key={id}
                      onClick={() => setActiveTab(id)}
                      className={`py-4 px-6 ${
                        activeTab === id
                          ? "border-b-2 border-blue-500 text-blue-600"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                    >
                      {label}
                    </button>
                  ))}
                </nav>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Ви впевнені що хочете видалити цього бота?"
                      )
                    ) {
                      deleteBot(selectedBot.id);
                    }
                  }}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                  Видалити бота
                </button>
              </div>

              <div className="p-6">
                {activeTab === "settings" && (
                  <div>
                    <BotSettings
                      bot={selectedBot}
                      setBot={setSelectedBot}
                      onSave={selectedBot.id ? updateBot : createBot}
                      setMessage={setMessage}
                    />

                    {selectedBot.id && (
                      <button
                        onClick={() => setWebhook(selectedBot.id)}
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      >
                        Встановити вебхук
                      </button>
                    )}
                  </div>
                )}
                {activeTab === "commands" && (
                  <CommandManager
                    bot={selectedBot}
                    setBot={setSelectedBot}
                    onSave={updateBot}
                    isOpenCommandsHint={isOpenCommandsHint}
                    setIsOpenCommandsHint={setIsOpenCommandsHint}
                    setMessage={setMessage}
                    addCommand={addCommand}
                    saveHandler={saveHandler}
                  />
                )}
                {activeTab === "handlers" && (
                  <HandlerManager
                    bot={selectedBot}
                    isOpenHandlersHint={isOpenHandlersHint}
                    setIsOpenHandlersHint={setIsOpenHandlersHint}
                    setMessage={setMessage}
                    fetchHandlers={fetchHandlers}
                    saveHandler={saveHandler}
                    updateHandler={updateHandler}
                    deleteHandler={deleteHandler}
                  />
                )}
                {activeTab === "keyboard" && (
                  <KeyboardManager
                    bot={selectedBot}
                    setBot={setSelectedBot}
                    onSave={updateKeyboard}
                    isOpenKeyboardHint={isOpenKeyboardHint}
                    setIsOpenKeyboardHint={setIsOpenKeyboardHint}
                    setMessage={setMessage}
                  />
                )}
                {activeTab === "menu" && (
                  <MenuManager
                    bot={selectedBot}
                    setBot={setSelectedBot}
                    onSave={updateBot}
                    setMessage={setMessage}
                    isOpenMenuHint={isOpenMenuHint}
                    setIsOpenMenuHint={setIsOpenMenuHint}
                    deleteMenuItem={deleteMenuItem}
                  />
                )}
                {activeTab === "states" && (
                  <StateManager bot={selectedBot} setMessage={setMessage} />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TelegramBotAPI;
