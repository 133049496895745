import React, { useState } from "react";

interface PostsNavigationProps {
    currentPage: number;
    totalPosts: number;
    postsPerPage: number;
    isLoading: boolean;
    hasMore: boolean;
    unreadCount: number;
    onPageChange: (page: number) => void;
  }
  
  export const PostsNavigation: React.FC<PostsNavigationProps> = ({
    currentPage,
    totalPosts,
    postsPerPage,
    isLoading,
    hasMore,
    unreadCount,
    onPageChange,
  }) => {
    const [jumpToPage, setJumpToPage] = useState('');
    const totalPages = Math.ceil(totalPosts / postsPerPage);
  
    const getPageNumbers = () => {
      const delta = 2; // Кількість сторінок з кожного боку
      const range = [];
      const rangeWithDots = [];
      let l;
  
      range.push(1);
  
      if (totalPages <= 1) return range;
  
      for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < totalPages && i > 1) {
          range.push(i);
        }
      }
      range.push(totalPages);
  
      for (let i = 0; i < range.length; i++) {
        if (l) {
          if (range[i] - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (range[i] - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(range[i]);
        l = range[i];
      }
  
      return (
        <div className="flex items-center gap-1">
          {rangeWithDots.map((number, index) => 
            number === '...' ? (
              <span key={`dots_${index}`} className="px-2">...</span>
            ) : (
              <button
                key={`page_${number}`}
                onClick={() => onPageChange(number as number)}
                disabled={isLoading}
                className={`px-3 py-1 rounded ${
                  currentPage === number
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                }`}
              >
                {number}
              </button>
            )
          )}
        </div>
      );
    };
  
    const handleJumpToPage = (e: React.FormEvent) => {
      e.preventDefault();
      const page = parseInt(jumpToPage);
      if (page > 0 && page <= totalPages) {
        onPageChange(page);
        setJumpToPage('');
      }
    };
  
    if (totalPosts === 0) return null;
  
    return (
      <div className="bg-white rounded-lg shadow p-4 mb-4">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          {/* Навігація по сторінках */}
          <div className="flex items-center gap-2">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1 || isLoading}
              className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200 disabled:opacity-50"
            >
              ←
            </button>
  
            {getPageNumbers()}
  
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={!hasMore || isLoading}
              className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200 disabled:opacity-50"
            >
              →
            </button>
          </div>
  
          {/* Форма швидкого переходу */}
          <div className="flex items-center gap-4">
            <form onSubmit={handleJumpToPage} className="flex items-center gap-2">
              <input
                type="number"
                min="1"
                max={totalPages}
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                placeholder="Сторінка..."
                className="w-20 px-2 py-1 border rounded"
              />
              <button
                type="submit"
                disabled={isLoading}
                className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Перейти
              </button>
            </form>
  
            <div className="text-sm text-gray-600">
              <span>{currentPage} з {totalPages} стор.</span>
              <span className="ml-4">Всього: {totalPosts}</span>
              {unreadCount > 0 && (
                <span className="ml-4 text-blue-500">
                  Непрочитано: {unreadCount}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };