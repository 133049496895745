import React, { useState } from "react";
import {
  User,
  Shield,
  Phone,
  Clock,
  Check,
  X,
  Star,
  AlertCircle,
  UserCheck,
  Video,
  Newspaper,
  MessageCircle,
  Globe,
  Calendar,
  LinkIcon,
  AtSign,
  Info,
} from "lucide-react";
import { UserPhoto, UserTelegram } from "@/types/telegram";
interface AuthorizedStepProps {
  user: UserTelegram;
  onLogout: () => void;
}
export const AuthorizedStep: React.FC<AuthorizedStepProps> = ({ user, onLogout }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const getProfilePhotoUrl = (photo?: UserPhoto): string | null => {
    if (!photo?.stripped_thumb?.bytes) {
      console.error("Error processing photo:");
      return null;
    }

    try { 
      if (!photo?.stripped_thumb?.bytes) {
         console.error("Error processing photo:");
        return null;
      }
      
      try {
        // 1. Декодуємо base64 в бінарні дані
        const base64 = photo.stripped_thumb.bytes;
        const binary = atob(base64);
        
        // 2. Створюємо буфер для JPG даних
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
          bytes[i] = binary.charCodeAt(i);
        }
        
        // 3. Розпаковуємо дані (stripped thumbnail - це стиснутий JPG)
        const jpegData = new Uint8Array(bytes.buffer);
        
        // 4. Створюємо Blob і URL
        const blob = new Blob([jpegData], { type: 'image/jpeg' });
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error processing photo:', error);
        return null;
      }         
    } catch (error) {
      console.error("Error processing photo:", error);
      return null;
    }
  };

  const formatPhoneNumber = (phone?: string) => {
    if (!phone) return "Не вказано";
    return `+${phone.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, "$1 $2 $3 $4")}`;
  };

  const formatLastOnline = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString("uk-UA", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const ProfilePhoto = () => (
    <div className="relative shrink-0">
      <div className="w-24 h-24 rounded-full bg-white/30 flex items-center justify-center overflow-hidden relative">
        {user.photo ? (
          <>
            {isImageLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-full">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
              </div>
            )}
            <img
              src={getProfilePhotoUrl(user.photo) || "/api/placeholder/96/96"}
              alt="Profile"
              className={`w-full h-full object-cover rounded-full bg-gray-200 ${isImageLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-200`}
              onLoad={() => setIsImageLoading(false)}
            /*   onError={(e) => {
                setIsImageLoading(false);
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = "/api/placeholder/98/98";
              }} */
            />
            {user.photo.has_video && (
              <div className="absolute bottom-0 right-0 bg-blue-500 p-1.5 rounded-full border-2 border-white">
                <Video className="w-4 h-4 text-white" />
              </div>
            )}
          </>
        ) : (
          <User className="w-12 h-12 text-white/70" />
        )}
      </div>
      {user.premium && (
        <div className="absolute -top-1 -right-1 bg-yellow-500 p-1.5 rounded-full border-2 border-white">
          <Star className="w-4 h-4 text-white" />
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full max-w-7xl mx-auto">
      {/* Шапка профілю */}
      <div className="bg-blue-600 rounded-t-xl p-8">
        <div className="flex items-start gap-6">
          <ProfilePhoto />
          <div className="flex-1 text-white pt-2">
            <div className="flex items-center gap-3 mb-2">
              <h2 className="text-3xl font-bold">
                {user.first_name} {user.last_name || ""}
              </h2>
              {user.verified && (
                <div className="bg-blue-500/50 p-1 rounded-full">
                  <Check className="w-5 h-5" />
                </div>
              )}
            </div>
            <div className="flex items-center gap-2 text-blue-100">
              <Shield className="w-4 h-4" />
              <span>ID: {user.id}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-b-xl shadow-lg">
        {/* Основна інформація */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 p-6">
          {/* Телефон */}
          <div className="bg-gray-50 rounded-xl p-4">
            <div className="flex items-center gap-3 mb-1 text-gray-500">
              <Phone className="w-5 h-5" />
              <span className="font-medium">Телефон</span>
            </div>
            <p className="text-lg pl-8">{formatPhoneNumber(user.phone)}</p>
          </div>

          {/* Username якщо є */}
          {user.username && (
            <div className="bg-gray-50 rounded-xl p-4">
              <div className="flex items-center gap-3 mb-1 text-gray-500">
                <AtSign className="w-5 h-5" />
                <span className="font-medium">Username</span>
              </div>
              <p className="text-lg pl-8">@{user.username}</p>
            </div>
          )}

          {/* Останній раз онлайн */}
          <div className="bg-gray-50 rounded-xl p-4">
            <div className="flex items-center gap-3 mb-1 text-gray-500">
              <Clock className="w-5 h-5" />
              <span className="font-medium">Останній раз онлайн</span>
            </div>
            <p className="text-lg pl-8">{formatLastOnline(user.status.was_online)}</p>
          </div>

          {/* Контакт */}
          <div className="bg-gray-50 rounded-xl p-4">
            <div className="flex items-center gap-3 mb-1 text-gray-500">
              <UserCheck className="w-5 h-5" />
              <span className="font-medium">Контакт</span>
            </div>
            <p className="text-lg pl-8">
              {user.contact ? (
                <span className="text-green-600 flex items-center gap-2">
                  <Check className="w-5 h-5" /> Так
                </span>
              ) : (
                <span className="text-red-500 flex items-center gap-2">
                  <X className="w-5 h-5" /> Ні
                </span>
              )}
            </p>
          </div>

          {/* Взаємний контакт */}
          <div className="bg-gray-50 rounded-xl p-4">
            <div className="flex items-center gap-3 mb-1 text-gray-500">
              <MessageCircle className="w-5 h-5" />
              <span className="font-medium">Взаємний контакт</span>
            </div>
            <p className="text-lg pl-8">
              {user.mutual_contact ? (
                <span className="text-green-600 flex items-center gap-2">
                  <Check className="w-5 h-5" /> Так
                </span>
              ) : (
                <span className="text-red-500 flex items-center gap-2">
                  <X className="w-5 h-5" /> Ні
                </span>
              )}
            </p>
          </div>

          {/* Історії */}
          <div className="bg-gray-50 rounded-xl p-4">
            <div className="flex items-center gap-3 mb-1 text-gray-500">
              <Newspaper className="w-5 h-5" />
              <span className="font-medium">Історії</span>
            </div>
            <p className="text-lg pl-8">
              {user.stories_unavailable ? (
                <span className="text-red-500">Недоступні</span>
              ) : user.stories_hidden ? (
                <span className="text-yellow-600">Приховані</span>
              ) : (
                <span className="text-green-600">Доступні</span>
              )}
            </p>
          </div>
        </div>

        {/* Системні статуси */}
        <div className="border-t border-gray-100">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-6">
            <div className="flex items-center gap-3 bg-gray-50 rounded-xl p-4">
              {user.bot ? (
                <span className="text-red-500 flex items-center gap-2">
                  <Check className="w-5 h-5" />
                  <span className="font-medium">Бот</span>
                </span>
              ) : (
                <span className="text-gray-400 flex items-center gap-2">
                  <X className="w-5 h-5" />
                  <span className="font-medium">Бот</span>
                </span>
              )}
            </div>

            <div className="flex items-center gap-3 bg-gray-50 rounded-xl p-4">
              {user.deleted ? (
                <span className="text-red-500 flex items-center gap-2">
                  <Check className="w-5 h-5" />
                  <span className="font-medium">Видалений</span>
                </span>
              ) : (
                <span className="text-gray-400 flex items-center gap-2">
                  <X className="w-5 h-5" />
                  <span className="font-medium">Видалений</span>
                </span>
              )}
            </div>

            <div className="flex items-center gap-3 bg-gray-50 rounded-xl p-4">
              {user.scam ? (
                <span className="text-red-500 flex items-center gap-2">
                  <AlertCircle className="w-5 h-5" />
                  <span className="font-medium">Scam</span>
                </span>
              ) : (
                <span className="text-gray-400 flex items-center gap-2">
                  <X className="w-5 h-5" />
                  <span className="font-medium">Scam</span>
                </span>
              )}
            </div>

            <div className="flex items-center gap-3 bg-gray-50 rounded-xl p-4">
              {user.fake ? (
                <span className="text-red-500 flex items-center gap-2">
                  <AlertCircle className="w-5 h-5" />
                  <span className="font-medium">Fake</span>
                </span>
              ) : (
                <span className="text-gray-400 flex items-center gap-2">
                  <X className="w-5 h-5" />
                  <span className="font-medium">Fake</span>
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Кнопка виходу */}
        <div className="border-t border-gray-100 p-6">
          <button className="w-full bg-red-500 hover:bg-red-600 text-white font-medium py-3 px-4 rounded-xl transition-colors duration-200" onClick={onLogout}>
            Вийти
          </button>
        </div>
      </div>
    </div>
  );
};
