import { CheckCircleIcon, XCircleIcon } from 'lucide-react';
export const AdminMetrics = ({ channelInfo }) => {
    const adminRights = channelInfo?.Chat?.admin_rights || {};
    const fullInfo = channelInfo?.full || {};
  
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-3">Метрики каналу</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Непрочитані повідомлення:</span>
              <span className="font-medium">{fullInfo.unread_count || 0}</span>
            </div>
            <div className="flex justify-between">
              <span>Останнє прочитане:</span>
              <span className="font-medium">{fullInfo.read_inbox_max_id || 0}</span>
            </div>
            <div className="flex justify-between">
              <span>Закріплене повідомлення:</span>
              <span className="font-medium">#{fullInfo.pinned_msg_id || 'Немає'}</span>
            </div>
          </div>
        </div>
  
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-3">Права адміністратора</h3>
          <div className="grid grid-cols-2 gap-2">
            {[
              { key: 'post_messages', label: 'Публікація постів' },
              { key: 'delete_messages', label: 'Видалення' },
              { key: 'edit_messages', label: 'Редагування' },
              { key: 'pin_messages', label: 'Закріплення' },
              { key: 'invite_users', label: 'Запрошення' },
              { key: 'change_info', label: 'Зміна інформації' }
            ].map(({ key, label }) => (
              <div key={key} className="flex items-center gap-2">
                {adminRights[key] ? (
                  <CheckCircleIcon className="text-green-500" size={16} />
                ) : (
                  <XCircleIcon className="text-red-500" size={16} />
                )}
                <span className="text-sm">{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  