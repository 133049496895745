import { useState, useEffect } from "react";
import axios from "axios";
import HistoryLoader from "./HistoryLoader";
import MessagesViewer from "./MessagesViewer";
import UserDataViewer from "./UserDataViewer";

const StateManager = ({ bot, setMessage }) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [commandInput, setCommandInput] = useState("");
  const [methodInput, setMethodInput] = useState("");
  const [methodParams, setMethodParams] = useState("{}");
  const [activeTab, setActiveTab] = useState("states");
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchStates = async () => {
    if (!bot?.id) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/states`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setStates(response.data.data);
    } catch (error) {
      console.error("Error fetching states:", error);
      setMessage("Помилка завантаження станів");
    } finally {
      setLoading(false);
    }
  };

  const clearState = async (stateToDelete) => {
    if (!bot?.id) return;
    // Підтвердження видалення
    const userConfirmed = window.confirm(
      "Ви впевнені, що хочете видалити цей стан? При цьому будуть видалені усі дані."
    );

    if (!userConfirmed) {
      return; // Користувач скасував видалення
    }
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      // Видалення стану з сервера
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/clear`,
        {
          user_id: stateToDelete.user_id,
          chat_id: stateToDelete.chat_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Оновлення локального списку станів
      setStates((prevStates) =>
        prevStates.filter((state) => state.chat_id !== stateToDelete.chat_id)
      );

      // Очистити вибраний стан, якщо він був видалений
      if (selectedState?.chat_id === stateToDelete.chat_id) {
        setSelectedState(null);
      }
    } catch (error) {
      console.error("Error deleting state:", error);
      setMessage("Помилка видалення стану");
    } finally {
      setLoading(false);
    }
  };

  const executeCommand = async () => {
    if (!selectedState || !commandInput) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/states/users/${selectedState.user_id}/command`,
        { command: commandInput },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage("Команду успішно виконано");
      setCommandInput("");
    } catch (error) {
      console.error("Error executing command:", error);
      setMessage("Помилка виконання команди");
    } finally {
      setLoading(false);
    }
  };

  const executeMethod = async () => {
    if (!selectedState || !methodInput) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      let params = {};
      try {
        params = JSON.parse(methodParams);
      } catch (e) {
        setMessage("Неправильний формат параметрів JSON");
        return;
      }

      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/states/users/${selectedState.user_id}/method`,
        {
          method: methodInput,
          params: params,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage("Метод успішно виконано");
      setMethodInput("");
      setMethodParams("{}");
    } catch (error) {
      console.error("Error executing method:", error);
      setMessage("Помилка виконання методу");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
  }, [bot?.id]);

  if (loading && !states.length) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Навігація по вкладках */}
      <div className="border-b border-gray-200">
        <nav className="flex space-x-4">
          {[
            { id: "states", label: "Стани користувачів" },
            { id: "history", label: "Історія та повідомлення" },
            { id: "user_data", label: "Дані користувача" },
            { id: "commands", label: "Виконання команд" },
          ].map(({ id, label }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`py-4 px-6 ${
                activeTab === id
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              {label}
            </button>
          ))}
        </nav>
      </div>

      {/* Таблиця станів */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4">Стани користувачів</h3>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="p-2 border text-left">ID користувача</th>
                <th className="p-2 border text-left">ID чату</th>
                <th className="p-2 border text-left">Стан</th>
                <th className="p-2 border text-left">Обробник</th>
                <th className="p-2 border text-left">Оновлено</th>
                <th className="p-2 border text-left">Дії</th>
              </tr>
            </thead>
            <tbody>
              {states.map((state) => (
                <tr
                  key={state.id}
                  className={`hover:bg-gray-50 ${
                    selectedState?.id === state.id ? "bg-blue-50" : ""
                  }`}
                >
                  <td className="p-2 border">{state.user_id}</td>
                  <td className="p-2 border">{state.chat_id}</td>
                  <td className="p-2 border">{state.state}</td>
                  <td className="p-2 border">{state.handler_name}</td>
                  <td className="p-2 border">
                    {new Date(state.updated_at).toLocaleString()}
                  </td>
                  <td className="p-2 border">
                    <button
                      onClick={() => setSelectedState(state)}
                      className="text-blue-500 hover:text-blue-700 pr-4"
                    >
                      Вибрати
                    </button>
                    <button
                      onClick={() => clearState(state)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Видалити
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Вміст вкладок */}
      {selectedState && (
        <>
          {activeTab === "user_data" && (
            <UserDataViewer bot={bot} selectedState={selectedState} />
          )}
          {activeTab === "history" && (
             <div key={selectedState?.id}>
              <HistoryLoader
                bot={bot}
                selectedState={selectedState}
                setMessage={setMessage}
              />
              <MessagesViewer
                bot={bot}
                selectedState={selectedState}
                setMessage={setMessage}
              />
            </div>
          )}

          {activeTab === "commands" && (
            <>
              {/* Виконання команд */}
              <div className="bg-white rounded-lg shadow p-6">
                <h3 className="text-lg font-semibold mb-4">
                  Виконання команд для користувача {selectedState.user_id}
                </h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Команда
                    </label>
                    <input
                      type="text"
                      value={commandInput}
                      onChange={(e) => setCommandInput(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Введіть команду"
                    />
                  </div>
                  <button
                    onClick={executeCommand}
                    disabled={!commandInput || loading}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
                  >
                    {loading ? "Виконання..." : "Виконати команду"}
                  </button>
                </div>
              </div>

              {/* Виконання методів */}
              <div className="bg-white rounded-lg shadow p-6 mt-4">
                <h3 className="text-lg font-semibold mb-4">
                  Виконання методів API для користувача {selectedState.user_id}
                </h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Метод API
                    </label>
                    <input
                      type="text"
                      value={methodInput}
                      onChange={(e) => setMethodInput(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Введіть назву методу"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Параметри (JSON)
                    </label>
                    <textarea
                      value={methodParams}
                      onChange={(e) => setMethodParams(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      rows={4}
                      placeholder="{ ... }"
                    />
                  </div>
                  <button
                    onClick={executeMethod}
                    disabled={!methodInput || loading}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
                  >
                    {loading ? "Виконання..." : "Виконати метод"}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default StateManager;
