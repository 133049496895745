import { Channel } from "@/types/telegram";
import { useState } from "react";

interface QuickNavigationProps {
    selectedChannel: Channel;
    executeMethod: (method: string, params: any) => Promise<any>;
    onPostsUpdate: (posts: any[]) => void;
  }
  
  export const QuickNavigation: React.FC<QuickNavigationProps> = ({
    selectedChannel,
    executeMethod,
    onPostsUpdate
  }) => {
    const [targetPostId, setTargetPostId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
  
    const handleJumpToPost = async (e: React.FormEvent) => {
      e.preventDefault();
      const postId = parseInt(targetPostId);
      
      if (!postId) {
        setError('Введіть ID поста');
        return;
      }
  
      setIsLoading(true);
      setError('');
  
      try {
        const result = await executeMethod("messages.getHistory", {
          peer: selectedChannel.id,
          offset_id: postId,
          offset_date: 0,
          add_offset: -5, // Щоб цільовий пост був посередині
          limit: 10,
          max_id: 0,
          min_id: 0,
          hash: 0
        });
  
        if (result.success) {
          const newPosts = result.result.messages.map(msg => ({
            id: msg.id,
            text: msg.message,
            date: msg.date,
            time: new Date(msg.date * 1000).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit"
            }),
            views: msg.views || 0,
            forwards: msg.forwards || 0,
            replies: msg.replies?.replies || 0
          }));
  
          onPostsUpdate(newPosts);
          setTargetPostId('');
  
          // Прокручуємо до потрібного поста
          setTimeout(() => {
            const postElement = document.getElementById(`post-${postId}`);
            if (postElement) {
              postElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              postElement.classList.add('bg-blue-50');
              setTimeout(() => postElement.classList.remove('bg-blue-50'), 2000);
            }
          }, 100);
        }
      } catch (err) {
        console.error('Error jumping to post:', err);
        setError('Помилка при пошуку поста');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div className="flex items-center gap-2">
        <form onSubmit={handleJumpToPost} className="flex items-center gap-2">
          <input
            type="number"
            value={targetPostId}
            onChange={(e) => {
              setTargetPostId(e.target.value);
              setError('');
            }}
            placeholder="ID поста"
            className={`w-32 px-2 py-1 border rounded ${error ? 'border-red-500' : ''}`}
            min="1"
          />
          <button
            type="submit"
            disabled={isLoading || !targetPostId}
            className={`px-3 py-1 rounded ${
              isLoading 
                ? 'bg-gray-300 cursor-not-allowed' 
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {isLoading ? 'Пошук...' : 'Знайти пост'}
          </button>
        </form>
        {error && <span className="text-sm text-red-500">{error}</span>}
      </div>
    );
  };
  