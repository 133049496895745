import React, { useEffect, useState } from "react";
import ModalCustom from "../../partial/ModalCustom";
import { handlersExamples } from "../constants/botConstructorHints";
import { BotData } from "../types/telegram";

interface HandlerManagerProps {
  bot: BotData;
  isOpenHandlersHint: boolean;
  setIsOpenHandlersHint: (isOpen: boolean) => void;
  setMessage: (message: string) => void;
  fetchHandlers: (botId: number) => Promise<any>;
  saveHandler: (botId: number, handlerData: any) => Promise<void>;
  updateHandler: (
    botId: number,
    handlerName: string,
    handlerData: any
  ) => Promise<void>;
  deleteHandler: (botId: number, handlerName: string) => Promise<void>;
}

const HandlerManager: React.FC<HandlerManagerProps> = ({
  bot,
  isOpenHandlersHint,
  setIsOpenHandlersHint,
  setMessage,
  fetchHandlers,
  saveHandler,
  updateHandler,
  deleteHandler,
}) => {
  const [handlers, setHandlers] = useState<Record<string, any>>({});
  const [selectedHandler, setSelectedHandler] = useState("");
  const [handlerCode, setHandlerCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bot.id) {
      loadHandlers();
    }
  }, [bot.id, bot.settings]);

  const loadHandlers = async () => {
    if (!bot.id) return;

    setLoading(true);
    try {
      // Якщо handlers приходять з API
      const data = await fetchHandlers(bot.id);
      if (data && data.handlers) {
        setHandlers(data.handlers);
      } else {
        // Якщо handlers знаходяться в settings бота
        const handlersFromSettings = bot.settings?.handlers || {};
        setHandlers(handlersFromSettings);
      }
    } catch (error) {
      // Якщо сталася помилка при запиті, спробуємо взяти з settings
      const handlersFromSettings = bot.settings?.handlers || {};
      setHandlers(handlersFromSettings);
      setMessage(
        "Помилка завантаження обробників з API, використано локальні дані"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!bot.id) {
      setMessage("Спочатку збережіть основні налаштування бота");
      return;
    }

    if (!selectedHandler) {
      setMessage("Виберіть назву обробника");
      return;
    }

    try {
      const handlerData = {
        name: selectedHandler,
        code: handlerCode,
        description: "",
      };

      // Перевіряємо чи існує вже такий обробник
      const isExisting = handlers[selectedHandler];

      if (isExisting) {
        // Якщо обробник існує - використовуємо PUT
        await updateHandler(bot.id, selectedHandler, handlerData);
        setMessage("Обробник успішно оновлено");
      } else {
        // Якщо новий обробник - використовуємо POST
        await saveHandler(bot.id, handlerData);
        setMessage("Обробник успішно створено");
      }

      await loadHandlers();
    } catch (error) {
      const action = handlers[selectedHandler] ? "оновлення" : "створення";
      setMessage(`Помилка ${action} обробника`);
    }
  };

  const handleSelectHandler = (name: string) => {
    setSelectedHandler(name);
    if (handlers[name]) {
      setHandlerCode(handlers[name].code);
    } else {
      setHandlerCode("");
    }
  };

  const handleUseExample = (name: string, code: string) => {
    setSelectedHandler(name);
    setHandlerCode(code);
    setIsOpenHandlersHint(false);
  };

  const handleDelete = async (handlerName: string) => {
    if (!bot.id || !window.confirm("Видалити обробник?")) return;

    await deleteHandler(bot.id, handlerName);
    const newHandlers = { ...handlers };
    delete newHandlers[handlerName];
    setHandlers(newHandlers);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Користувацькі обробники</h3>
        <button
          onClick={() => setIsOpenHandlersHint(true)}
          className="text-blue-500 hover:text-blue-600"
        >
          Приклади обробників
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center py-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="space-y-4">
          <div>
            <label className="block mb-1">Виберіть обробник:</label>
            <select
              value={selectedHandler}
              onChange={(e) => handleSelectHandler(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Створити новий обробник</option>
              {Object.keys(handlers).map((handler) => (
                <option key={handler} value={handler}>
                  {handler}
                </option>
              ))}
            </select>
          </div>
          {Object.keys(handlers).map((handler) => (
            <div key={handler} className="flex justify-between items-center">
              <span>{handler}</span>
              <button
                onClick={() => handleDelete(handler)}
                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
              >
                Видалити
              </button>
            </div>
          ))}
          <div>
            <label className="block mb-1">Код обробника:</label>
            <textarea
              value={handlerCode}
              onChange={(e) => setHandlerCode(e.target.value)}
              className="w-full p-2 border rounded font-mono"
              rows={10}
              placeholder="<?php"
            />
          </div>

          <button
            onClick={handleSave}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Зберегти обробник
          </button>
        </div>
      )}

      {/* Модальне вікно з прикладами */}
      {isOpenHandlersHint && (
        <ModalCustom
          isOpen={isOpenHandlersHint}
          closeModal={() => setIsOpenHandlersHint(false)}
          text="Приклад обробника"
          zIndex={10}
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">
              Приклади користувацьких обробників:
            </h3>
            {Object.entries(handlersExamples).map(([name, handler]) => (
              <div key={name} className="mb-6 bg-gray-50 p-4 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-semibold text-blue-600">{name}</h4>
                  <button
                    onClick={() => handleUseExample(name, handler.code)}
                    className="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    Використати обробник
                  </button>
                </div>
                <p className="text-gray-600 mb-2">{handler.description}</p>
                <div className="w-full overflow-hidden">
                  <pre className="bg-gray-100 p-4 rounded whitespace-pre-wrap break-all text-sm">
                    {handler.code}
                  </pre>
                </div>
              </div>
            ))}
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default HandlerManager;
