 export class TelegramService {
    private readonly apiUrl = process.env.REACT_APP_API_URL;
    private readonly API_URL = `${this.apiUrl}/telegram/base`;
    private static instance: TelegramService;

    private constructor() { }

    public static getInstance(): TelegramService {
        if (!TelegramService.instance) {
            TelegramService.instance = new TelegramService();
        }
        return TelegramService.instance;
    }

    private getAuthHeaders(): HeadersInit {
        const token = localStorage.getItem('token');
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
    }

    // Методи авторизації USER-BOT
    // Auth methods
    async checkAuth(params: { phone_number?: string; use_bot?: boolean }) {
        try {
            const response = await fetch(`${this.API_URL}/check-auth`, {
                method: 'POST',
                headers: this.getAuthHeaders(),
                body: JSON.stringify(params)
            });

            return await response.json();
        } catch (error) {
            console.error('Check auth error:', error);
            throw error;
        }
    }

    async startAuth(phoneNumber: string) {
        try {
            const response = await fetch(`${this.API_URL}/start-auth`, {
                method: 'POST',
                headers: this.getAuthHeaders(),
                body: JSON.stringify({
                    phone_number: phoneNumber
                })
            });

            return await response.json();
        } catch (error) {
            console.error('Start auth error:', error);
            throw error;
        }
    }

    async completeAuth(params: { 
        phone_number: string; 
        code?: string; 
        password?: string; 
    }) {
        try {
            const response = await fetch(`${this.API_URL}/complete-auth`, {
                method: 'POST',
                headers: this.getAuthHeaders(),
                body: JSON.stringify(params)
            });

            return await response.json();
        } catch (error) {
            console.error('Complete auth error:', error);
            throw error;
        }
    }

    async clearAuth() {
        try {
            const response = await fetch(`${this.API_URL}/clear-auth`, {
                method: 'POST',
                headers: this.getAuthHeaders()
            });

            return await response.json();
        } catch (error) {
            console.error('Start auth error:', error);
            throw error;
        }
    }

    // API методи для роботи з Telegram
    async executeMethod(method: string, params: any = {}) {
        try {
            const response = await fetch(`${this.API_URL}/execute-method`, {
                method: 'POST',
                headers: this.getAuthHeaders(),
                body: JSON.stringify({ method, params })
            });

            return await response.json();
        } catch (error) {
            console.error(`Execute method error (${method}):`, error);
            throw error;
        }
    }

    async executeCommand(command: string, params: any = {}) {
        try {
            const response = await fetch(`${this.API_URL}/execute-command`, {
                method: 'POST',
                headers: this.getAuthHeaders(),
                body: JSON.stringify({ command, ...params })
            });

            return await response.json();
        } catch (error) {
            console.error(`Execute command error (${command}):`, error);
            throw error;
        }
    }

    // Допоміжні методи для роботи з каналами через executeCommand
    async getChannels() {
        return this.executeCommand('get-info');
    }

    async getChannelMessages(channel: string, limit = 10) {
        return this.executeCommand('get-messages', {
            channel,
            message_limit: limit,
            get_messages: true
        });
    }

    async sendMessage(channel: string, message: string) {
        return this.executeCommand('publish-message', {
            channel,
            message
        });
    }

}

export const telegramService = TelegramService.getInstance();