import React, { useState } from 'react';
import axios from 'axios';

const HistoryLoader = ({ bot, selectedState, setMessage }) => {
  const [loading, setLoading] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const loadDialogs = async () => {
    if (!bot?.id || !selectedState) return;
    
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/dialogs`,
        {
          user_id: selectedState.user_id,
          offset_id: 0,
          limit: 100
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        setMessage('Діалоги успішно завантажено');
      } else {
        setMessage('Помилка при завантаженні діалогів');
      }
    } catch (error) {
      console.error('Error loading dialogs:', error);
      setMessage('Помилка завантаження діалогів');
    } finally {
      setLoading(false);
    }
  };

  const loadHistory = async (peerId) => {
    if (!bot?.id || !selectedState) return;
    
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/messages`,
        {
          user_id: selectedState.user_id,
          peer: peerId,
          offset_id: 0,
          limit: 100
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        setMessage('Історію повідомлень успішно завантажено');
        checkMissingMessages(peerId);
      } else {
        setMessage('Помилка при завантаженні історії');
      }
    } catch (error) {
      console.error('Error loading history:', error);
      setMessage('Помилка завантаження історії');
    } finally {
      setLoading(false);
    }
  };

  const checkMissingMessages = async (peerId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/check-missing/${peerId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.data.success && response.data.gaps.length > 0) {
        setMessage(`Знайдено ${response.data.gaps.length} пропущених сегментів повідомлень`);
        // Можна додати автоматичне відновлення пропущених повідомлень
      }
    } catch (error) {
      console.error('Error checking missing messages:', error);
    }
  };

  const checkProcessingStatus = async (peerId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/status/${peerId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        setProcessingStatus(response.data.status);
      }
    } catch (error) {
      console.error('Error checking processing status:', error);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h3 className="text-lg font-semibold mb-4">Завантаження історії</h3>
      
      <div className="space-y-4">
        <div>
          <button
            onClick={loadDialogs}
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-300 mr-4"
          >
            {loading ? 'Завантаження...' : 'Завантажити діалоги'}
          </button>
          
          <button
            onClick={() => selectedState?.chat_id && loadHistory(selectedState.chat_id)}
            disabled={loading || !selectedState?.chat_id}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-gray-300"
          >
            {loading ? 'Завантаження...' : 'Завантажити історію повідомлень'}
          </button>
        </div>

        {processingStatus && (
          <div className="mt-4">
            <div className="text-sm text-gray-600">
              Статус обробки: {processingStatus}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryLoader;