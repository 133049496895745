// components/TelegramManager/index.tsx
import React, { useState } from 'react';
import { TelegramAuth } from './TelegramAuth';
import { TelegramChannels } from './TelegramChannels';
import styles from './style/TelegramManager.module.css';
import {TelegramBaseSettings} from './TelegramBaseSettings';
import ChannelManager from './Chanel/ChannelManager';
import { Channel } from '@/types/telegram';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedChannel } from "../../../store/slices/channelsSlice";
import { RootState } from '@/types';
type TabType = 'auth' | 'channels' | 'settings';

export const BaseTelegramManager: React.FC = () => {
    const [activeTab, setActiveTab] = useState<TabType>('auth'); 
    const dispatch = useDispatch();
    const selectedChannel = useSelector((state: RootState) => state.channels.selectedChannel);
 
    const handleSelectChannel = (channel: Channel) => {
      setSelectedChannel(channel);
      dispatch(setSelectedChannel(channel));
    };
    
    const handleBackToChannels = () => {
      setSelectedChannel(null);
    };
  
    const renderContent = () => {
      switch (activeTab) {
        case 'auth':
          return <TelegramAuth />;
        case 'channels':
          if (selectedChannel) {
            return (
              <ChannelManager 
                onBack={handleBackToChannels} 
              />
            );
          }
          return (
            <TelegramChannels onSelectChannel={handleSelectChannel} />
          );
        case 'settings':
          return <TelegramBaseSettings />;
        default:
          return null;
      }
    };
  
    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.tabs}>
            <button 
              className={`${styles.tab} ${activeTab === 'auth' ? styles.active : ''}`}
              onClick={() => setActiveTab('auth')}
            >
              <span className={styles.icon}>🔐</span>
              Авторизація
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'channels' ? styles.active : ''}`}
              onClick={() => {
                setActiveTab('channels');
                setSelectedChannel(null); // Скидаємо вибраний канал при переключенні таба
              }}
            >
              <span className={styles.icon}>📢</span>
              Канали
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'settings' ? styles.active : ''}`}
              onClick={() => setActiveTab('settings')}
            >
              <span className={styles.icon}>⚙️</span>
              Налаштування
            </button>
          </div>
        </header>
  
        <main className={styles.content}>
          {renderContent()}
        </main>
      </div>
    );
  };