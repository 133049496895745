// components/TelegramAuth/index.tsx
import React, { useState, useEffect } from "react";
import { PhoneStep } from "./PhoneStep";
import { CodeStep } from "./CodeStep";
import { TwoFactorStep } from "./TwoFactorStep";
import styles from "../style/TelegramAuth.module.css";
import { useTelegramAuth } from "../../../../Hooks/useTelegramAuth";
import { AuthorizedStep } from "./AuthorizedStep";
import { UserTelegram } from "@/types/telegram";

export const TelegramAuth: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<"phone" | "code" | "2fa" | "authorized">("phone");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCodeHash, setPhoneCodeHash] = useState<string | null>(null);
  const [user, setUser] = useState<UserTelegram | null>(null);

  const { isLoading, error, authStatus, checkAuth, startAuth, clearAuth, completeAuth } = useTelegramAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  // Обробка успішної авторизації
  useEffect(() => {
    if (authStatus?.isAuthorized) {
      setCurrentStep("authorized");
      setUser(authStatus.user);
      console.log("user", authStatus);
      console.log("Successfully authorized!");
    }
  }, [authStatus?.isAuthorized]);

  const handlePhoneSubmit = async (phone: string) => {
    try {
      const result = await startAuth(phone);

      if (result.success && result.requiresCode) {
        setPhoneNumber(phone);
        setPhoneCodeHash(result.phoneCodeHash);
        setCurrentStep("code");
      }
    } catch (error) {
      console.error("Phone step error:", error);
    }
  };

  const handleCodeSubmit = async (code: string) => {
    try {
      const resultApi = await completeAuth({
        phone_number: phoneNumber,
        code: code,
        phone_code_hash: phoneCodeHash,
      });
      const { success, result } = resultApi; 
      if (resultApi.requires2FA) {
        setCurrentStep("2fa");
      } else if (resultApi.isAuthorized) {
        setUser(result.user); // Зберігаємо дані користувача
        setCurrentStep("authorized");
      }
    } catch (error) {
      console.error("Code step error:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const result = await clearAuth();
      if (result.success) {
        setUser(null);
        setCurrentStep("phone");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handle2FASubmit = async (password: string) => {
    try {
      const result = await completeAuth({
        phone_number: phoneNumber,
        password,
        phone_code_hash: phoneCodeHash,
      });

      if (result.success && result.isAuthorized) {
        // Успішна авторизація
        setCurrentStep("authorized");
        console.log("Successfully authorized with 2FA");
      }
    } catch (error) {
      console.error("2FA error:", error);
    }
  };

  return (
    <div className={styles.authContainer}>
      <div className={styles.authCard}>
        {/* Індикатор прогресу */}
        <div className={styles.authProgress}>
          {["phone", "code", "2fa"].map((step) => (
            <div key={step} className={`${styles.progressStep} ${currentStep === step ? styles.active : ""}`} />
          ))}
        </div>

        {/* Відображення помилок */}
        {error && <div className={styles.errorMessage}>{error}</div>}

        {/* Кроки авторизації */}
        {currentStep === "phone" && <PhoneStep onSubmit={handlePhoneSubmit} isLoading={isLoading} />}

        {currentStep === "code" && <CodeStep onSubmit={handleCodeSubmit} onBack={() => setCurrentStep("phone")} isLoading={isLoading} />}

        {currentStep === "2fa" && <TwoFactorStep onSubmit={handle2FASubmit} onBack={() => setCurrentStep("code")} isLoading={isLoading} />}
        {currentStep === "authorized" && user && <AuthorizedStep user={user} onLogout={handleLogout} />}

        {/* Відображення статусу */}
        {authStatus?.isAuthorized && !user && <div className={styles.successMessage}>Авторизація успішна!</div>}
        {!user && (
          <button onClick={handleLogout} className={styles.submitButton}>
            Clear Session
          </button>
        )}
      </div>
    </div>
  );
};
