import { useState, useCallback } from 'react';
import { telegramService } from '../services/telegramApi';
import { Channel } from '@/types/telegram';

export const useTelegram = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [channels, setChannels] = useState<Channel[]>([]);

    const getChannels = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await telegramService.getChannels();
            setChannels(response.channels);
            return response.channels;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const sendMessage = useCallback(async (channelId: string, message: string) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await telegramService.sendMessage(channelId, message);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    /**
    * Виконання універсального методу Telegram API
    */
    const executeMethod = useCallback(async (method: string, params: Record<string, any> = {}) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await telegramService.executeMethod(method, params);
            return response;
        } catch (err: any) {
            setError(err.message || 'Something went wrong');
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);
    /**
        * Виконання методу Telegram API з опцією use_bot
        */
    const executeBotMethod = useCallback(async (method: string, params: Record<string, any> = {}) => {
        setIsLoading(true);
        setError(null);

        try {
            // Додаємо use_bot: true до params
            const response = await telegramService.executeMethod(method, { ...params, use_bot: true });
            return response;
        } catch (err: any) {
            setError(err.message || 'Something went wrong');
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);
    return {
        isLoading,
        error,
        channels,
        getChannels,
        sendMessage,
        executeMethod,
        executeBotMethod
    };
};