import React, { useState, useEffect } from "react";
import styles from "./style/TelegramChannels.module.css";
import { Channel } from "@/types/telegram";
import { useTelegram } from "../../../Hooks/useTelegram";
import { DraftPost } from "./DraftPost";
import { ChannelSettings } from "./ChannelSettings";
import { useDispatch } from "react-redux";
import { setChannels as setChannelsAction } from "../../../store/slices/channelsSlice";
import ModalCustom from "../../../components/partial/ModalCustom";
interface TelegramChannelsProps {
  onSelectChannel: (channel: Channel) => void;
}

export const TelegramChannels: React.FC<TelegramChannelsProps> = ({ onSelectChannel }) => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { isLoading, error, executeMethod } = useTelegram();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDialogs = async () => {
      try {
        const result = await executeMethod("messages.getDialogs", {
          offset_date: 0,
          offset_id: 0,
          offset_peer: { _: "inputPeerEmpty" },
          limit: 100,
          hash: 0,
        });

        console.log("Dialogs:", result);
        // Фільтруємо тільки канали з результату
        const channels = result.channels.filter((channel) => channel._ === "channel");
        setChannels(channels);
        dispatch(setChannelsAction(channels));
      } catch (err) {
        console.error("Error fetching dialogs:", err);
      }
    };

    fetchDialogs();
  }, [executeMethod, dispatch]);

  return (
    <div className={styles.channelsContainer}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <h2 className={styles.title}>Мої канали</h2>
          <div className={styles.filters}>
            <button className={`${styles.filterButton} ${styles.active}`}>Всі канали</button>
            <button className={styles.filterButton}>Активні</button>
            <button className={styles.filterButton}>Чернетки</button>
            <button
              onClick={() => {
                /* Показати модальне вікно додавання каналу */
              }}
            >
              Add Channel
            </button>
          </div>
        </div>

        <div className={styles.headerRight}>
          <input type="text" className={styles.searchInput} placeholder="Пошук каналів..." />
          <button className={styles.addButton} onClick={() => setIsNewPostModalOpen(true)}>
            <span>✚</span> Новий пост
          </button>
        </div>
      </div>

      {error && <div className={styles.error}>{error}</div>}

      <div className={styles.channelsList}>
        {channels &&
          channels.map((channel) => (
            <div key={channel.id} className={styles.channelCard} >
              <div className={styles.channelHeader} onClick={() => onSelectChannel(channel)}>
                <div className={styles.channelAvatar}>
                  {channel.photo ? (
                    <img
                      src={`/api/placeholder/96/96`} // тут можна використати stripped_thumb
                      alt={channel.title}
                    />
                  ) : (
                    channel.title[0]
                  )}
                </div>
                <div className={styles.channelInfo}>
                  <h3 className={styles.channelTitle}>
                    {channel.title}
                    {channel.verified && <span className={styles.verifiedBadge}>✓</span>}
                  </h3>
                  {channel.username && <div className={styles.channelUsername}>@{channel.username}</div>}
                  <div className={styles.channelStats}>
                    <div className={styles.statItem}>
                      <div className={styles.statValue}>{channel.participants_count}</div>
                      <div className={styles.statLabel}>Підписників</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.channelActions}>
                <button
                  className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
                  onClick={() => {
                    setSelectedChannel(channel);
                    setIsNewPostModalOpen(true);
                  }}
                >
                  Створити пост
                </button>
                <button
                  className={styles.actionButton}
                  onClick={() => {
                    setSelectedChannel(channel);
                    setIsSettingsModalOpen(false);
                  }}
                >
                  Налаштування
                </button>
              </div>
            </div>
          ))}
      </div>

      {/* Модальне вікно нового поста */}
      {isNewPostModalOpen && (
         <ModalCustom isOpen={isNewPostModalOpen} closeModal={() => setIsNewPostModalOpen(false)} text={{ title: "Open chanel" }} maxW={"max-w-7xl"}>
         
        <DraftPost
          channel={selectedChannel}
          onClose={() => {
            setIsNewPostModalOpen(false);
            setSelectedChannel(null);
          }}
        />
         </ModalCustom>
      )}

      {/* Модальне вікно налаштувань */}
      {isSettingsModalOpen && (
        <ChannelSettings
          channel={selectedChannel}
          onClose={() => {
            setIsSettingsModalOpen(false);
            setSelectedChannel(null);
          }}
        />
      )}
    </div>
  );
};
