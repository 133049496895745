import React, { useState } from 'react';
import { 
  MenuIcon, MessageCircleIcon, BoldIcon, ItalicIcon, 
  LinkIcon, ListIcon, ImageIcon, XIcon, CalendarIcon
} from 'lucide-react';
import { PostData, TextFormatting } from '@/types/telegram';

interface EditorPostProps {
  onClose: () => void;
  onSave: (post: PostData) => void;
}



export const EditorPost: React.FC<EditorPostProps> = ({ onClose, onSave }) => {
  const [postText, setPostText] = useState('');
  const [media, setMedia] = useState<File[]>([]);
  const [formatting, setFormatting] = useState<TextFormatting[]>([]);
  const [scheduleDate, setScheduleDate] = useState<Date | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedText, setSelectedText] = useState({ start: 0, end: 0 });

  const handleFileUpload = (files: FileList) => {
    const validFiles = Array.from(files).filter(file => 
      file.type.startsWith('image/') || file.type.startsWith('video/')
    );
    setMedia([...media, ...validFiles]);
  };

  const removeMedia = (index: number) => {
    setMedia(media.filter((_, i) => i !== index));
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files);
  };

  const applyFormatting = (type: 'bold' | 'italic' | 'link' | 'list') => {
    const textarea = document.querySelector('textarea');
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start === end) return;

    setFormatting([...formatting, { type, start, end }]);
    
    if (type === 'link') {
      const url = prompt('Введіть URL:');
      if (url) {
        setFormatting([...formatting, { type, start, end, data: url }]);
      }
    }
  };

  const renderFormattedText = () => {
    let text = postText;
    formatting.sort((a, b) => b.start - a.start).forEach(format => {
      const before = text.slice(0, format.start);
      const formatted = text.slice(format.start, format.end);
      const after = text.slice(format.end);

      switch (format.type) {
        case 'bold':
          text = `${before}**${formatted}**${after}`;
          break;
        case 'italic':
          text = `${before}*${formatted}*${after}`;
          break;
        case 'link':
          text = `${before}[${formatted}](${format.data})${after}`;
          break;
        case 'list':
          text = `${before}• ${formatted}${after}`;
          break;
      }
    });
    return text;
  };

  const handleSave = () => {
    onSave({
      text: renderFormattedText(),
      media,
      scheduleDate,
      formatting
    });
    onClose();
  };

  return (
    <div className="">
      <div className="bg-white">
        <header className="p-4 border-b flex justify-between items-center">
          <h1 className="text-xl font-bold">Планування постів</h1>
          <div className="space-x-2">
            <button onClick={() => setShowPreview(!showPreview)} className="px-4 py-2 bg-gray-100 rounded">
              {showPreview ? 'Сховати превʼю' : 'Показати превʼю'}
            </button>
            <button onClick={onClose} className="px-4 py-2 bg-gray-100 rounded">
              Скасувати
            </button>
            <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded">
              Опублікувати
            </button>
          </div>
        </header>

        <div className="flex-1 overflow-hidden flex">
          <div className="flex-1 p-4 overflow-y-auto">
            <div className="bg-white rounded-lg shadow">
              <div className="flex gap-2 p-2 border-b">
                <button onClick={() => applyFormatting('bold')} className="p-2 hover:bg-gray-100 rounded">
                  <BoldIcon size={16} />
                </button>
                <button onClick={() => applyFormatting('italic')} className="p-2 hover:bg-gray-100 rounded">
                  <ItalicIcon size={16} />
                </button>
                <button onClick={() => applyFormatting('link')} className="p-2 hover:bg-gray-100 rounded">
                  <LinkIcon size={16} />
                </button>
                <button onClick={() => applyFormatting('list')} className="p-2 hover:bg-gray-100 rounded">
                  <ListIcon size={16} />
                </button>
                <div className="flex-1"></div>
                <button className="flex items-center gap-2 text-blue-500">
                  <CalendarIcon size={16} />
                  <input
                    type="datetime-local"
                    onChange={(e) => setScheduleDate(new Date(e.target.value))}
                    className="border rounded p-1"
                  />
                </button>
              </div>

              <div 
                className="border-2 border-dashed rounded-lg p-8 text-center m-4"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <ImageIcon className="mx-auto mb-2" size={40} />
                <p>Перетягніть файли або клікніть для завантаження</p>
                <input
                  type="file"
                  multiple
                  accept="image/*,video/*"
                  className="hidden"
                  onChange={(e) => e.target.files && handleFileUpload(e.target.files)}
                />
              </div>

              {media.length > 0 && (
                <div className="flex flex-wrap gap-4 p-4">
                  {media.map((file, index) => (
                    <div key={index} className="relative group">
                      <img 
                        src={URL.createObjectURL(file)} 
                        alt="Preview"
                        className="w-32 h-32 object-cover rounded"
                      />
                      <button
                        onClick={() => removeMedia(index)}
                        className="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow
                                 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <XIcon size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <textarea
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
                onSelect={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setSelectedText({
                    start: target.selectionStart,
                    end: target.selectionEnd
                  });
                }}
                placeholder="Введіть текст поста..."
                className="w-full p-4 min-h-[200px] border-0 rounded focus:ring-0"
              />
            </div>
          </div>

          {showPreview && (
            <div className="w-1/3 border-l p-4 overflow-y-auto">
              <div className="bg-white rounded-lg shadow p-4">
                <h2 className="text-lg font-bold mb-4">Попередній перегляд</h2>
                <div className="prose max-w-none">
                  {renderFormattedText().split('\n').map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                  {media.length > 0 && (
                    <div className="mt-4 space-y-2">
                      {media.map((file, index) => (
                        <img 
                          key={index}
                          src={URL.createObjectURL(file)} 
                          alt="Preview"
                          className="max-w-full rounded"
                        />
                      ))}
                    </div>
                  )}
                </div>
                {scheduleDate && (
                  <div className="mt-4 text-sm text-gray-500">
                    Заплановано на: {scheduleDate.toLocaleString()}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};