import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import projectReducer from './slices/projectSlice';
import projectsReducer from './slices/projectsSlice';
import channelsReducer from './slices/channelsSlice';

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  projects: projectsReducer,
  channels: channelsReducer,
});

export default rootReducer;
