import { Channel, PostData } from "@/types/telegram";
import { useState } from "react";
import styles from "./style/TelegramChannels.module.css";
import { EditorPost } from "./EditorPost";
import { useTelegram } from "../../../Hooks/useTelegram";
interface DraftPostProps {
  channel: Channel | null;
  onClose: () => void;
  onSave: (postData: PostData) => Promise<void>;
}

export const DraftPost: React.FC<DraftPostProps> = ({ channel, onClose, onSave }) => {
  const [activeTab, setActiveTab] = useState("editor");

  const { executeMethod } = useTelegram();

  const handleSavePost = async (postData: PostData) => {
    try {
      const messageParams = {
        peer: channel.id,
        message: postData.text,
        silent: false,
        random_id: Math.floor(Math.random() * 1000000000),
        media: undefined,
        schedule_date: postData.scheduleDate ? Math.floor(postData.scheduleDate.getTime() / 1000) : undefined,
      };

      if (postData.media?.length > 0) {
        const mediaResults = await Promise.all(
          postData.media.map(async (file) => {
            const formData = new FormData();
            formData.append("file", file);

            const uploadResult = await executeMethod("messages.uploadMedia", {
              peer: messageParams.peer,
              media: formData,
            });
            return uploadResult.result;
          })
        );
        messageParams.media = mediaResults;
      }

      const result = await executeMethod("messages.sendMessage", messageParams);
      if (result.success) onClose();
    } catch (error) {
      console.error("Error publishing post:", error);
    }
  };

  return (
    <div className={`${styles.modalContent} bg-opacity-70`}>
      <div className={styles.modalHeader}>
        <h3>Новий пост для каналу: {channel?.title}</h3>
        <button onClick={onClose} className={styles.closeButton}>
          ×
        </button>
      </div>

      <div className={styles.modalBody}>
        <div className="mb-4 border-b">
          <button className={`px-4 py-2 ${activeTab === "editor" ? "border-b-2 border-blue-500" : ""}`} onClick={() => setActiveTab("editor")}>
            Редактор
          </button>
          <button className={`px-4 py-2 ${activeTab === "scheduled" ? "border-b-2 border-blue-500" : ""}`} onClick={() => setActiveTab("scheduled")}>
            Заплановані пости
          </button>
        </div>

        {activeTab === "editor" ? (
          <EditorPost
            onClose={onClose}
            onSave={async (data) => {
              await onSave(data);
              onClose();
            }}
          />
        ) : (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Заплановані пости</h2>
          </div>
        )}
      </div>
    </div>
  );
};
