import { useState, useEffect } from "react";
import axios from "axios";
import TelegramSettings from "./TelegramSettings";
import PostManagement from "./PostManagement";
import GameManagement from "./GameManagement";
import MessageSender from "./MessageSender";
import JoinRequestsManagement from "./JoinRequestsManagement";
import WebAppManagement from "./WebAppManagement";
//import BotConstructor from "./BotConstructor";
import WebhookManagement from "./WebhookManagement";
import TelegramBotAPI from "./TelegramBotAPI";

const TelegramManagement = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [activeTab, setActiveTab] = useState("settings");

  const tabs = [
    { id: "settings", label: "Basic Settings", component: TelegramSettings },
    { id: "constructor", label: "Bot Constructor", component: TelegramBotAPI },
    { id: "webhook", label: "Webhook", component: WebhookManagement }, // Додали новий таб
    { id: "posts", label: "Posts", component: PostManagement },
    { id: "games", label: "Games", component: GameManagement },
    { id: "webapp", label: "Web App", component: WebAppManagement },
    { id: "messages", label: "Messages", component: MessageSender },
    { id: "requests", label: "Join Requests", component: JoinRequestsManagement },
  ];

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/projects`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setMessage("Error fetching projects");
    }
  };

  const handleProjectChange = (e) => {
    setSelectedProjectId(e.target.value);
  };
  const renderActiveComponent = () => {
    const ActiveComponent = tabs.find(tab => tab.id === activeTab)?.component;
    if (ActiveComponent) {
      return <ActiveComponent projectId={selectedProjectId} setMessage={setMessage} />;
    }
    return null;
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Telegram Bot Management</h1>

      <div className="mb-4">
        <label className="block mb-2">Select Project:</label>
        <select 
          value={selectedProjectId} 
          onChange={handleProjectChange} 
          className="w-full p-2 border rounded"
        >
          <option value="">Select a project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
      </div>


      {message && (
        <p className="mt-4 text-center font-semibold">{message}</p>
      )}

      {selectedProjectId && (
        <>
          {/* Tabs Navigation */}
          <div className="mb-6 border-b">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    py-2 px-1 border-b-2 font-medium text-sm
                    ${activeTab === tab.id
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>

          {/* Active Component */}
          <div className="mt-4">
            {renderActiveComponent()}
          </div>
        </>
      )}
    </div>
  );
};

export default TelegramManagement;
