import React from "react";
import ModalCustom from "../../partial/ModalCustom";
import {
  keyboardExamples,
  keyboardRecommendations,
} from "../constants/botConstructorHints";
import { BotData } from "../types/telegram";

interface KeyboardManagerProps {
  bot: BotData;
  setBot: (bot: BotData) => void;
  onSave: (botId: number, keyboardData: any) => Promise<void>;
  isOpenKeyboardHint: boolean;
  setIsOpenKeyboardHint: (isOpen: boolean) => void;
  setMessage: (message: string) => void;
}

const KeyboardManager: React.FC<KeyboardManagerProps> = ({
  bot,
  setBot,
  onSave,
  isOpenKeyboardHint,
  setIsOpenKeyboardHint,
  setMessage,
}) => {
  const addRow = () => {
    // Створюємо копію бота з гарантованою ініціалізацією keyboard_settings
    setBot({
      ...bot,
      keyboard_settings: {
        type: bot.keyboard_settings?.type || "reply",
        buttons: [...(bot.keyboard_settings?.buttons || []), []],
      },
    });
  };

  const addButton = (rowIndex: number) => {
    const buttons = bot.keyboard_settings?.buttons || [];
    const newButtons = [...buttons];
    newButtons[rowIndex] = [
      ...(newButtons[rowIndex] || []),
      { text: "Нова кнопка" },
    ];

    setBot({
      ...bot,
      keyboard_settings: {
        type: bot.keyboard_settings?.type || "reply",
        buttons: newButtons,
      },
    });
  };

  const updateButton = (
    rowIndex: number,
    buttonIndex: number,
    field: string,
    value: string
  ) => {
    const buttons = bot.keyboard_settings?.buttons || [];
    const newButtons = [...buttons];
    newButtons[rowIndex] = [...(newButtons[rowIndex] || [])];
    newButtons[rowIndex][buttonIndex] = {
      ...newButtons[rowIndex][buttonIndex],
      [field]: value,
    };

    setBot({
      ...bot,
      keyboard_settings: {
        type: bot.keyboard_settings?.type || "reply",
        buttons: newButtons,
      },
    });
  };

  const removeButton = (rowIndex: number, buttonIndex: number) => {
    const newButtons = [...bot.keyboard_settings.buttons];
    newButtons[rowIndex] = newButtons[rowIndex].filter(
      (_, index) => index !== buttonIndex
    );

    // Видаляємо рядок, якщо він порожній
    if (newButtons[rowIndex].length === 0) {
      newButtons.splice(rowIndex, 1);
    }

    setBot({
      ...bot,
      keyboard_settings: {
        ...bot.keyboard_settings,
        buttons: newButtons,
      },
    });
  };

  const handleSave = async () => {
    if (!bot.id) {
      setMessage("Спочатку збережіть основні налаштування бота");
      return;
    }

    try {
      await onSave(bot.id, bot.keyboard_settings);
      setMessage("Налаштування клавіатури збережено");
    } catch (error) {
      setMessage("Помилка збереження налаштувань клавіатури");
    }
  };

  const useKeyboardExample = (type: "reply" | "inline") => {
    const example =
      type === "reply" ? keyboardExamples.reply : keyboardExamples.inline;
    setBot({
      ...bot,
      keyboard_settings: {
        type,
        buttons: example.buttons,
      },
    });
    setIsOpenKeyboardHint(false);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Налаштування клавіатури</h3>
        <button
          onClick={() => setIsOpenKeyboardHint(true)}
          className="text-blue-500 hover:text-blue-600"
        >
          Приклади налаштувань
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block mb-1">Тип клавіатури:</label>
          <select
            value={bot.keyboard_settings?.type}
            onChange={(e) =>
              setBot({
                ...bot,
                keyboard_settings: {
                  ...bot.keyboard_settings,
                  type: e.target.value as "reply" | "inline",
                },
              })
            }
            className="w-full p-2 border rounded"
          >
            <option value="reply">Reply Keyboard</option>
            <option value="inline">Inline Keyboard</option>
          </select>
        </div>

        <div className="space-y-4">
          {(bot.keyboard_settings?.buttons || []).map((row, rowIndex) => (
            <div key={rowIndex} className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Ряд {rowIndex + 1}</span>
                <button
                  onClick={() => addButton(rowIndex)}
                  className="text-sm bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                >
                  + Додати кнопку
                </button>
              </div>

              <div className="flex flex-wrap gap-2">
                {row.map((button, buttonIndex) => (
                  <div
                    key={buttonIndex}
                    className="flex-1 min-w-[200px] space-y-2 p-2 border rounded"
                  >
                    <input
                      type="text"
                      value={button.text}
                      onChange={(e) =>
                        updateButton(
                          rowIndex,
                          buttonIndex,
                          "text",
                          e.target.value
                        )
                      }
                      className="w-full p-2 border rounded"
                      placeholder="Текст кнопки"
                    />
                    {bot.keyboard_settings.type === "inline" && (
                      <input
                        type="text"
                        value={button.callback_data || ""}
                        onChange={(e) =>
                          updateButton(
                            rowIndex,
                            buttonIndex,
                            "callback_data",
                            e.target.value
                          )
                        }
                        className="w-full p-2 border rounded"
                        placeholder="callback_data"
                      />
                    )}
                    <button
                      onClick={() => removeButton(rowIndex, buttonIndex)}
                      className="w-full bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 text-sm"
                    >
                      Видалити
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={addRow}
          className="w-full bg-gray-100 py-2 px-4 rounded hover:bg-gray-200"
        >
          + Додати новий ряд кнопок
        </button>

        <button
          onClick={handleSave}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Зберегти налаштування клавіатури
        </button>
      </div>

      {/* Модальне вікно з прикладами */}
      {isOpenKeyboardHint && (
        <ModalCustom
          isOpen={isOpenKeyboardHint}
          closeModal={() => setIsOpenKeyboardHint(false)}
          text="Приклади налаштувань клавіатури"
          zIndex={10}
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">Типи клавіатур:</h3>

            <div className="mb-4">
              <h4 className="font-semibold flex justify-between items-center">
                <span>Reply Keyboard</span>
                <button
                  onClick={() => useKeyboardExample("reply")}
                  className="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                >
                  Використати приклад
                </button>
              </h4>
              <pre className="bg-gray-100 p-4 rounded overflow-x-auto mt-2">
                {JSON.stringify(keyboardExamples.reply, null, 2)}
              </pre>
            </div>

            <div className="mb-4">
              <h4 className="font-semibold flex justify-between items-center">
                <span>Inline Keyboard</span>
                <div className="flex gap-2">
                  <button
                    onClick={() => useKeyboardExample("inline")}
                    className="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    Використати приклад
                  </button>
                </div>
              </h4>
              <p className="text-sm text-gray-600 mt-1 mb-2">
                💡 Для обробки callback_data створіть відповідний обробник у
                розділі "Обробники"
              </p>
              <pre className="bg-gray-100 p-4 rounded overflow-x-auto mt-2">
                {JSON.stringify(keyboardExamples.inline, null, 2)}
              </pre>
            </div>

            <div className="mt-4">
              <h4 className="font-semibold">Рекомендації:</h4>
              <ul className="list-disc list-inside space-y-2">
                {keyboardRecommendations.map((rec, index) => (
                  <li key={index}>{rec}</li>
                ))}
              </ul>
            </div>
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default KeyboardManager;
