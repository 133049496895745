// hooks/useTelegramAuth.ts
import { useCallback, useState } from "react";
import { telegramService } from "../services/telegramApi";
import { TelegramAuthResult } from "@/types/telegram";

export const useTelegramAuth = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<TelegramAuthResult | null>(null);

    const checkAuth = useCallback(async (phoneNumber?: string, useBot?: boolean) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await telegramService.checkAuth({
                phone_number: phoneNumber,
                use_bot: useBot
            });
            setAuthStatus(response);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const startAuth = useCallback(async (phoneNumber: string): Promise<TelegramAuthResult> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await telegramService.startAuth(phoneNumber);
            setAuthStatus(response);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const clearAuth = useCallback(async (): Promise<TelegramAuthResult> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await telegramService.clearAuth();
            setAuthStatus(response);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const completeAuth = useCallback(async (params: {
        phone_number: string;
        code?: string;
        password?: string;
        phone_code_hash?: string;
    }) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await telegramService.completeAuth(params);
            setAuthStatus(response);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    return {
        isLoading,
        error,
        authStatus,
        checkAuth,
        startAuth,
        clearAuth,
        completeAuth,
        isAuthorized: authStatus?.isAuthorized || false
    };
};

/* // Для авторизації:
await startAuth(phoneNumber);
await completeAuth(code, password);

// Для роботи з API:
const channelsResult = await telegramService.executeCommand('get-info');
const messageResult = await telegramService.executeMethod('messages.sendMessage', {
    peer: '@channel',
    message: 'Hello'
}); */