import { useTelegram } from "../../../../Hooks/useTelegram";
import { Channel } from "@/types/telegram";
import { useEffect, useState } from "react";
import { DraftPost } from "../DraftPost";
import { useDispatch, useSelector } from "react-redux";
import { MessageParams, RootState } from "@/types";
import { setSelectedChannel } from "../../../../store/slices/channelsSlice";
import { ChevronDownIcon, CheckIcon, PodcastIcon } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { AdminMetrics } from "./AdminMetrics";
import { QuickNavigation } from "../Post/QuickNavigation";
import { PostsNavigation } from "../Post/PostsNavigation";
import MediaContent from "../Media/MediaContent";
import { MediaContentDownload } from "../Media/MediaContentDownload";

interface ChannelManagerProps {
  channelId?: number;
  channelData?: Channel; // додаємо channelData в пропси
  onBack: () => void;
}
interface PostFilters {
  hasMedia: boolean;
  hasLinks: boolean;
  dateFrom: string | null;
  dateTo: string | null;
  searchText: string;
}

const ChannelManager: React.FC<ChannelManagerProps> = ({ onBack }) => {
  const { executeMethod } = useTelegram();
  const [channelInfo, setChannelInfo] = useState<any>(null);
  const dispatch = useDispatch();
  const { items: channels, selectedChannel } = useSelector(
    (state: RootState) => state.channels
  );
  const [isChannelsDropdownOpen, setIsChannelsDropdownOpen] = useState(false);
  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false);

  // Стани для постів та пагінації
  const [posts, setPosts] = useState([]);
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [postsPage, setPostsPage] = useState(1);
  const [postsLimit] = useState(10);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [totalPosts, setTotalPosts] = useState(0);

  // Стан для фільтрів
  const [postFilters, setPostFilters] = useState<PostFilters>({
    hasMedia: false,
    hasLinks: false,
    dateFrom: null,
    dateTo: null,
    searchText: "",
  });

  const [stats, setStats] = useState({
    subscribers: 0,
    posts: 0,
    engagement: "0%",
    interactions: 0,
    canPost: false,
  });

  const toggleDropdown = () =>
    setIsChannelsDropdownOpen(!isChannelsDropdownOpen);

  useEffect(() => {
    if (selectedChannel?.id) {
      fetchChannelData();
      fetchChannelHistory();
    }
  }, [selectedChannel]);

  const fetchChannelData = async () => {
    try {
      // Отримуємо повну інформацію про канал
      const fullInfo = await executeMethod("getFullInfo", {
        id: selectedChannel.username
          ? `@${selectedChannel.username}`
          : selectedChannel.id,
      });

      if (fullInfo.success) {
        setChannelInfo(fullInfo.result);
        setStats({
          subscribers: fullInfo.result.full.participants_count || 0,
          posts: fullInfo.result.full.read_inbox_max_id || 0,
          engagement: calculateEngagement(fullInfo.result.full),
          interactions: fullInfo.result.full.read_outbox_max_id || 0,
          canPost: fullInfo.result.Chat?.admin_rights?.post_messages || false,
        });
      }
      console.log("Channel info:", channelInfo, fullInfo, fullInfo.result);
    } catch (err) {
      console.error("Error fetching channel data:", err);
    }
  };

  // Функція для розрахунку engagement
  const calculateEngagement = (channelFull: any) => {
    if (!channelFull.participants_count) return "0%";

    // Можемо розрахувати engagement як співвідношення переглядів до підписників
    const views = channelFull.read_inbox_max_id || 0;
    const subscribers = channelFull.participants_count;
    const engagementRate = (views / subscribers) * 100;

    return `${Math.round(engagementRate)}%`;
  };

  const handlePostsUpdate = (newPosts: any[]) => {
    setPosts(newPosts);
  };

  // Отримання історії повідомлень
  const fetchChannelHistory = async (offset = 0) => {
    setIsPostsLoading(true);
    try {
      const history = await executeMethod("messages.getHistory", {
        peer: selectedChannel.username
          ? `@${selectedChannel.username}`
          : selectedChannel.id,
        offset_id: offset,
        offset_date: 0,
        add_offset: 0,
        limit: postsLimit,
        max_id: 0,
        min_id: 0,
        hash: 0,
      });

      if (history.success) {
        const newPosts = history.result.messages.map((msg) => ({
          id: msg.id,
          text: msg.message,
          date: msg.date,
          time: new Date(msg.date * 1000).toLocaleTimeString("uk-UA", {
            hour: "2-digit",
            minute: "2-digit",
          }),
          views: msg.views || 0,
          forwards: msg.forwards || 0,
          replies: msg.replies?.replies || 0,
          media: msg.media,
          isUnread: msg.id > channelInfo?.full?.read_inbox_max_id,
        }));

        setTotalPosts(history.result.count);
        setHasMorePosts(offset + postsLimit < history.result.count);

        if (offset === 0) {
          setPosts(newPosts);
        } else {
          setPosts((prev) => [...prev, ...newPosts]);
        }
      }
    } catch (err) {
      console.error("Error fetching channel history:", err);
    } finally {
      setIsPostsLoading(false);
    }
  };

  const handleSavePost = async (postData: any) => {
    try {
      const messageParams: MessageParams = {
        peer: selectedChannel.id,
        message: postData.text,
        silent: false,
        random_id: Math.floor(Math.random() * 1000000000),
        schedule_date: postData.scheduleDate
          ? Math.floor(postData.scheduleDate.getTime() / 1000)
          : undefined,
      };

      if (postData.media?.length) {
        const mediaResults = await Promise.all(
          postData.media.map(async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            return (
              await executeMethod("messages.uploadMedia", {
                peer: messageParams.peer,
                media: formData,
              })
            ).result;
          })
        );
        messageParams.media = mediaResults;
      }

      const result = await executeMethod("messages.sendMessage", messageParams);

      if (result.success) {
        fetchChannelHistory(0);
        setIsNewPostModalOpen(false);
      }
    } catch (error) {
      console.error("Error publishing post:", error);
    }
  };

  const renderPost = (post) => {
    return (
      <div
        id={`post-${post.id}`}
        className={`${styles.postCard} ${
          post.isUnread ? "border-l-4 border-blue-500" : ""
        }`}
      >
        {post.media && <MediaContent media={post.media} />}
        {/* {post.media && (
          <MediaContentDownload
            media={post.media}
            messageId={post.id}
            channelId={selectedChannel.id}
            executeMethod={executeMethod}
          />
        )}*/}
        <div className={styles.postContent}>
          <ReactMarkdown>{post.text}</ReactMarkdown>
        </div>

        <div className={styles.postMeta}>
          <span className={styles.postTime}>{post.time}</span>
          <div className={styles.postStats}>
            <span title="Перегляди">👁 {post.views?.toLocaleString()}</span>
            <span title="Репости">↪️ {post.forwards?.toLocaleString()}</span>
            {post.replies && (
              <span title="Коментарі">💬 {post.replies.replies}</span>
            )}
            {renderReactions(post.reactions)}
          </div>
          <div className="text-xs text-gray-500 mt-2">ID: {post.id}</div>
        </div>
      </div>
    );
  };

  const renderReactions = (reactions: any) => {
    if (!reactions || !reactions.results) return null;

    return (
      <div className="flex items-center gap-2">
        {reactions.results.map((reactionCount) => (
          <span
            key={`${reactionCount.reaction.emoticon}_${reactionCount.count}`}
            className="flex items-center gap-1 bg-gray-100 px-2 py-1 rounded-full text-sm"
            title="Реакції"
          >
            {reactionCount.reaction.emoticon}
            <span className="text-gray-600">{reactionCount.count}</span>
          </span>
        ))}
      </div>
    );
  };

  const styles = {
    channelManagerContainer: "max-w-4xl mx-auto p-4",
    managerHeader: "flex justify-between items-center mb-6",
    addChannelBtn: "bg-blue-500 text-white px-4 py-2 rounded",
    statsGrid: "grid grid-cols-2 md:grid-cols-4 gap-4 mb-8",
    statCard: "bg-white p-4 rounded-lg shadow text-center",
    statValue: "text-2xl font-bold text-blue-500",
    statLabel: "text-gray-600",
    postActions: "flex justify-between items-center",
    recentPosts: "space-y-4",
    postText: "mb-4",
    postMeta: "flex justify-between items-center text-gray-600 text-sm",
    postStats: "flex gap-4",
    postTime: "text-sm text-gray-500 font-medium",
    loadingSpinner: "flex justify-center items-center p-4",
    errorMessage: "text-red-500 text-center p-4",
    postCard:
      "@apply bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow",
    postMetrics: "flex items-center gap-3 text-gray-600",
    metricItem: "flex items-center gap-1 text-sm",
    newPostSection: "bg-white rounded-lg shadow p-4 mb-8",
    postTextarea:
      "w-full p-3 border rounded min-h-[100px] mb-4 focus:ring-2 focus:ring-blue-500 focus:outline-none",
    mediaBtn:
      "flex items-center gap-2 text-blue-500 hover:text-blue-600 transition-colors px-4 py-2 rounded-lg hover:bg-blue-50",
    backButton:
      "bg-grey-500 hover:bg-grey-600 text-gray-600 px-6 py-2 rounded-lg transition-colors",
    publishBtn:
      "bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors",
    messageMedia: "mb-4 rounded-lg overflow-hidden",
    mediaPreview: "w-full h-auto",
    mediaPhoto: "w-full h-auto object-cover",
    postContent:
      "prose max-w-none prose-img:rounded-lg prose-pre:bg-gray-50 prose-pre:p-4 prose-pre:rounded-lg",
    "@keyframes highlightPost": {
      "0%": { backgroundColor: "rgba(59, 130, 246, 0.1)" },
      "100%": { backgroundColor: "transparent" },
    },
    "highlight-post": {
      animation: "highlightPost 2s ease-out",
    },
  };

  return (
    <div className={styles.channelManagerContainer}>
      {/* Шапка з назвою каналу та кнопкою */}
      <div className={styles.managerHeader}>
        <button onClick={onBack} className={styles.backButton}>
          ← Назад
        </button>
        <h1>Telegram Admin</h1>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="flex items-center gap-2 px-4 py-2 bg-white border rounded-lg hover:bg-gray-50"
          >
            <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
              {selectedChannel?.photo ? (
                <img
                  src={`/api/placeholder/32/32`}
                  alt={selectedChannel?.title}
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <span>{selectedChannel?.title[0]}</span>
              )}
            </div>
            <span className="font-medium">{selectedChannel?.title}</span>
            <ChevronDownIcon
              size={16}
              className={`transform transition-transform ${
                isChannelsDropdownOpen ? "rotate-180" : ""
              }`}
            />
          </button>

          {isChannelsDropdownOpen && (
            <div className="absolute top-full left-0 mt-1 w-64 bg-white rounded-lg shadow-lg py-2 z-50">
              {channels.map((channel) => (
                <button
                  key={channel.id}
                  className={`w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2 ${
                    channel.id === selectedChannel?.id ? "bg-blue-50" : ""
                  }`}
                  onClick={async () => {
                    await dispatch(setSelectedChannel(channel));
                    setIsChannelsDropdownOpen(false);
                  }}
                >
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    {channel.photo ? (
                      <img
                        src={`/api/placeholder/32/32`}
                        alt={channel.title}
                        className="w-full h-full rounded-full object-cover"
                      />
                    ) : (
                      <span>{channel.title[0]}</span>
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="font-medium truncate">{channel.title}</div>
                    {channel.username && (
                      <div className="text-sm text-gray-500 truncate">
                        @{channel.username}
                      </div>
                    )}
                  </div>
                  {(channel.verified || channel.broadcast) && (
                    <div className="flex gap-1">
                      {channel.verified && (
                        <CheckIcon size={16} className="text-blue-500" />
                      )}
                      {channel.broadcast && (
                        <PodcastIcon size={16} className="text-gray-500" />
                      )}
                    </div>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
        <button
          onClick={() => setIsNewPostModalOpen(true)}
          disabled={!stats.canPost}
          className={`px-4 py-2 rounded-lg transition-colors ${
            stats.canPost
              ? "bg-blue-500 hover:bg-blue-600 text-white"
              : "bg-gray-200 text-gray-500 cursor-not-allowed"
          }`}
        >
          Створити пост
        </button>
      </div>

      <AdminMetrics channelInfo={channelInfo} />

      {/* Статистика */}
      {stats && (
        <div className={styles.statsGrid}>
          <div className={styles.statCard}>
            <h2 className={styles.statValue}>
              {stats.subscribers.toLocaleString()}
            </h2>
            <p className={styles.statLabel}>Підписників</p>
          </div>
          <div className={styles.statCard}>
            <h2 className={styles.statValue}>{stats.posts}</h2>
            <p className={styles.statLabel}>Постів</p>
          </div>
          <div className={styles.statCard}>
            <h2 className={styles.statValue}>{stats.engagement}</h2>
            <p className={styles.statLabel}>Охоплення</p>
          </div>
          <div className={styles.statCard}>
            <h2 className={styles.statValue}>{stats.interactions}</h2>
            <p className={styles.statLabel}>Взаємодій</p>
          </div>
        </div>
      )}

      {/* Основна інформація */}
      {channelInfo && (
        <>
          {/* Опис каналу */}
          {channelInfo.full.about && (
            <div className="p-4 bg-white rounded-lg shadow mb-4">
              <p className="whitespace-pre-wrap">{channelInfo.full.about}</p>
            </div>
          )}

          {/* Можливості каналу */}
          <div className="p-4 bg-white rounded-lg shadow mb-4">
            <h3 className="font-semibold mb-2">Можливості:</h3>
            <div className="grid grid-cols-2 gap-2">
              <div>
                Перегляд учасників:{" "}
                {channelInfo.full.can_view_participants ? "✅" : "❌"}
              </div>
              <div>
                Зміна юзернейму:{" "}
                {channelInfo.full.can_set_username ? "✅" : "❌"}
              </div>
              <div>
                Встановлення стікерів:{" "}
                {channelInfo.full.can_set_stickers ? "✅" : "❌"}
              </div>
              <div>
                Перегляд статистики:{" "}
                {channelInfo.full.can_view_stats ? "✅" : "❌"}
              </div>
            </div>
          </div>

          {/* Додаткова інформація */}
          {channelInfo.full.linked_chat_id && (
            <div className="p-4 bg-white rounded-lg shadow mb-4">
              <p>Є пов'язаний чат для обговорень</p>
            </div>
          )}
        </>
      )}

      {/* Основна інформація */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Загальна інформація</h2>
          <div className="space-y-2">
            <div>
              <span className="text-gray-600">Тип:</span>{" "}
              {channelInfo?.type === "supergroup" ? "Супергрупа" : "Канал"}
            </div>
            <div>
              <span className="text-gray-600">Учасників:</span>{" "}
              {channelInfo?.full.participants_count}
            </div>
            <div>
              <span className="text-gray-600">Онлайн:</span>{" "}
              {channelInfo?.full.online_count}
            </div>
          </div>
        </div>

        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Налаштування</h2>
          <div className="space-y-2">
            <div>
              <span className="text-gray-600">Підписи:</span>{" "}
              {channelInfo?.Chat.signatures ? "Увімкнено" : "Вимкнено"}
            </div>
            <div>
              <span className="text-gray-600">Прихована історія:</span>{" "}
              {channelInfo?.full.hidden_prehistory ? "Так" : "Ні"}
            </div>
            <div>
              <span className="text-gray-600">
                Можна переглядати учасників:
              </span>{" "}
              {channelInfo?.full.can_view_participants ? "Так" : "Ні"}
            </div>
          </div>
        </div>
      </div>

      {/* Список учасників */}
      {channelInfo?.participants && (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Останні учасники</h2>
          <div className="space-y-2">
            {channelInfo.participants.map((participant: any) => (
              <div key={participant.date} className="p-2 bg-gray-50 rounded">
                <div>
                  Дата приєднання:{" "}
                  {new Date(participant.date * 1000).toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Додаткова інформація */}
      {channelInfo?.full.pinned_msg_id && (
        <div className="mt-4 p-4 bg-white rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-2">Інше</h2>
          <div>
            ID закріпленого повідомлення: {channelInfo.full.pinned_msg_id}
          </div>
        </div>
      )}
      {/* Останні пости */}
      <div className={styles.recentPosts}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Пости каналу</h2>
          <QuickNavigation
            selectedChannel={selectedChannel}
            executeMethod={executeMethod}
            onPostsUpdate={handlePostsUpdate}
          />
        </div>

        <PostsNavigation
          currentPage={postsPage}
          totalPosts={totalPosts}
          postsPerPage={postsLimit}
          isLoading={isPostsLoading}
          hasMore={hasMorePosts}
          unreadCount={channelInfo?.full?.unread_count || 0}
          onPageChange={(page) => {
            setPostsPage(page);
            const offset = (page - 1) * postsLimit;
            fetchChannelHistory(offset);
          }}
        />

        <div className="space-y-6">
          {isPostsLoading && (
            <div className="text-center py-4">Завантаження...</div>
          )}

          {posts
            .filter((post) => {
              if (postFilters.hasMedia && !post.media) return false;
              if (postFilters.hasLinks && !post.text.includes("http"))
                return false;
              if (
                postFilters.searchText &&
                !post.text
                  .toLowerCase()
                  .includes(postFilters.searchText.toLowerCase())
              )
                return false;
              if (postFilters.dateFrom || postFilters.dateTo) {
                const postDate = new Date(post.date * 1000);
                if (
                  postFilters.dateFrom &&
                  postDate < new Date(postFilters.dateFrom)
                )
                  return false;
                if (
                  postFilters.dateTo &&
                  postDate > new Date(postFilters.dateTo)
                )
                  return false;
              }
              return true;
            })
            .map((post) => renderPost(post))}
        </div>

        {hasMorePosts && !isPostsLoading && (
          <button
            onClick={() => {
              setPostsPage((p) => p + 1);
              fetchChannelHistory(posts[posts.length - 1].id);
            }}
            className="w-full py-2 bg-gray-100 text-gray-600 rounded-lg mt-4 hover:bg-gray-200"
          >
            Завантажити ще
          </button>
        )}
      </div>

      {isNewPostModalOpen && (
        <DraftPost
          channel={selectedChannel}
          onClose={() => setIsNewPostModalOpen(false)}
          onSave={handleSavePost}
        />
      )}
    </div>
  );
};

export default ChannelManager;
