import { Canvas } from "@grapesjs/react";
import { useWidth } from "../../providers/WidthProvider";

const MainContent = () => {
  const { width } = useWidth();

  return (
    <main>
      <div className="w-full lg:h-[74vh] 2xl:h-[80vh] flex 2xl:justify-center sm:justify-end md:justify-end lg:justify-center xl:justify-end items-center bg-white dark:bg-gray-800 dark:text-white transition-colors highlight">
        {/* Оновлені класи для вирівнювання */}
        {/*    <Canvas className="gjs-custom-editor-canvas  lg:max-w-4xl 2xl:max-w-6xl" />     */}
        <Canvas
          className="gjs-custom-editor-canvas w-full"
          style={{ maxWidth: width, maxHeight: "70vh", overflowY: "auto" }}
        />{" "}
        {/* Оновлені власні стилі */}
      </div>
    </main>
  );
};

export default MainContent;
