import { Channel } from "@/types/telegram";
import styles from './style/TelegramChannels.module.css';
import { useState } from "react";

interface ChannelSettingsProps {
    channel: Channel | null;
    onClose: () => void;
}

export const ChannelSettings: React.FC<ChannelSettingsProps> = ({ channel, onClose }) => {
    const [settings, setSettings] = useState({
        autoPosting: false,
        notificationsEnabled: true,
        defaultScheduleTime: '',
        postingInterval: 60,
        signature: '',
        watermark: false,
        autoDeleteMessages: false,
        messageTemplate: '',
        buttons: []
    });

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h3>Налаштування каналу: {channel?.title}</h3>
                    <button onClick={onClose} className={styles.closeButton}>×</button>
                </div>

                <div className={styles.modalBody}>
                    {/* Основні налаштування */}
                    <div className={styles.settingsSection}>
                        <h4>Основні налаштування</h4>
                        
                        <div className={styles.settingItem}>
                            <label className={styles.settingLabel}>
                                Автоматична публікація
                                <div className={styles.settingDescription}>
                                    Автоматично публікувати пости в заданий час
                                </div>
                            </label>
                            <div className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={settings.autoPosting}
                                    onChange={(e) => setSettings({
                                        ...settings,
                                        autoPosting: e.target.checked
                                    })}
                                    className={styles.switchInput}
                                />
                                <span className={styles.switchSlider}></span>
                            </div>
                        </div>

                        {settings.autoPosting && (
                            <div className={styles.settingItem}>
                                <label className={styles.settingLabel}>
                                    Час публікації за замовчуванням
                                </label>
                                <input
                                    type="time"
                                    value={settings.defaultScheduleTime}
                                    onChange={(e) => setSettings({
                                        ...settings,
                                        defaultScheduleTime: e.target.value
                                    })}
                                    className={styles.settingInput}
                                />
                            </div>
                        )}
                    </div>

                    {/* Налаштування форматування */}
                    <div className={styles.settingsSection}>
                        <h4>Форматування постів</h4>
                        
                        <div className={styles.settingItem}>
                            <label className={styles.settingLabel}>
                                Підпис до постів
                            </label>
                            <input
                                type="text"
                                value={settings.signature}
                                onChange={(e) => setSettings({
                                    ...settings,
                                    signature: e.target.value
                                })}
                                placeholder="Наприклад: via @channel_name"
                                className={styles.settingInput}
                            />
                        </div>

                        <div className={styles.settingItem}>
                            <label className={styles.settingLabel}>
                                Водяний знак на медіа
                            </label>
                            <div className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={settings.watermark}
                                    onChange={(e) => setSettings({
                                        ...settings,
                                        watermark: e.target.checked
                                    })}
                                    className={styles.switchInput}
                                />
                                <span className={styles.switchSlider}></span>
                            </div>
                        </div>
                    </div>

                    {/* Шаблони повідомлень */}
                    <div className={styles.settingsSection}>
                        <h4>Шаблон повідомлень</h4>
                        
                        <div className={styles.settingItem}>
                            <textarea
                                value={settings.messageTemplate}
                                onChange={(e) => setSettings({
                                    ...settings,
                                    messageTemplate: e.target.value
                                })}
                                placeholder="Шаблон для постів..."
                                className={styles.settingTextarea}
                            />
                            <div className={styles.settingDescription}>
                                Доступні змінні: {'{text}'}, {'{date}'}, {'{link}'}
                            </div>
                        </div>
                    </div>

                    {/* Кнопки */}
                    <div className={styles.settingsSection}>
                        <h4>Кнопки</h4>
                        <div className={styles.buttonsList}>
                            {settings.buttons.map((button, index) => (
                                <div key={index} className={styles.buttonItem}>
                                    {/* Тут буде редагування кнопок */}
                                </div>
                            ))}
                            <button className={styles.addButton}>
                                + Додати кнопку
                            </button>
                        </div>
                    </div>

                    {/* Додаткові налаштування */}
                    <div className={styles.settingsSection}>
                        <h4>Додаткові налаштування</h4>
                        
                        <div className={styles.settingItem}>
                            <label className={styles.settingLabel}>
                                Автовидалення повідомлень
                                <div className={styles.settingDescription}>
                                    Автоматично видаляти повідомлення через заданий час
                                </div>
                            </label>
                            <div className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={settings.autoDeleteMessages}
                                    onChange={(e) => setSettings({
                                        ...settings,
                                        autoDeleteMessages: e.target.checked
                                    })}
                                    className={styles.switchInput}
                                />
                                <span className={styles.switchSlider}></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.modalFooter}>
                    <button 
                        className={styles.secondaryButton} 
                        onClick={onClose}
                    >
                        Скасувати
                    </button>
                    <button className={styles.primaryButton}>
                        Зберегти налаштування
                    </button>
                </div>
            </div>
        </div>
    );
};