// store/slices/channelsSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { Channel } from '@/types/telegram';

interface ChannelsState {
  items: Channel[];
  selectedChannel: Channel | null;
}

const initialState: ChannelsState = {
  items: [],
  selectedChannel: null
};

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    setChannels: (state, action) => {
      state.items = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    clearSelectedChannel: (state) => {
      state.selectedChannel = null;
    }
  }
});

export const { setChannels, setSelectedChannel, clearSelectedChannel } = channelsSlice.actions;
export default channelsSlice.reducer;