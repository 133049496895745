// src/components/WebhookManagement.tsx

import { useState, useEffect } from 'react';
import axios from 'axios';

interface WebhookManagementProps {
  projectId: string;
  setMessage: (message: string) => void;
}

const WebhookManagement = ({ projectId, setMessage }: WebhookManagementProps) => {
  const [webhookInfo, setWebhookInfo] = useState<any>(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (projectId) {
      fetchWebhookInfo();
    }
  }, [projectId]);

  const fetchWebhookInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${projectId}/webhook`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setWebhookInfo(response.data);
    } catch (error) {
      console.error('Error fetching webhook info:', error);
      setMessage('Error fetching webhook info');
    }
  };

  const setWebhook = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${apiUrl}/telegram/telegram-bots/${projectId}/webhook`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage('Webhook set successfully');
      fetchWebhookInfo();
    } catch (error) {
      console.error('Error setting webhook:', error);
      setMessage('Error setting webhook');
    }
  };

  const deleteWebhook = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(
        `${apiUrl}/telegram/telegram-bots/${projectId}/webhook`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage('Webhook deleted successfully');
      fetchWebhookInfo();
    } catch (error) {
      console.error('Error deleting webhook:', error);
      setMessage('Error deleting webhook');
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">Webhook Management</h2>
      
      <div className="bg-white p-4 rounded shadow">
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Current Webhook Status:</h3>
          {webhookInfo ? (
            <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
              {JSON.stringify(webhookInfo, null, 2)}
            </pre>
          ) : (
            <p>No webhook information available</p>
          )}
        </div>

        <div className="flex gap-4">
          <button
            onClick={setWebhook}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Set Webhook
          </button>
          <button
            onClick={deleteWebhook}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Delete Webhook
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebhookManagement;