import { useState, useEffect } from "react";
import axios from "axios";
import { User, Shield, Phone, Camera, Database, Globe, Lock, Activity } from "lucide-react";

const UserDataViewer = ({ bot, selectedState }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUserData = async () => {
    if (!bot?.id || !selectedState?.user_id) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/user`,
        { user_id: selectedState.user_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        setUserData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [bot?.id, selectedState?.user_id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-12 gap-4 mt-4">
      {/* Основна інформація */}
      <div className="col-span-4">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center mb-4">
            <User className="w-5 h-5 mr-2" />
            <h3 className="text-lg font-semibold">Основна інформація</h3>
          </div>
          
          {userData?.self && (
            <div className="space-y-2">
              <div className="grid grid-cols-2 gap-2">
                <span className="text-gray-600">ID:</span>
                <span>{userData.self.id}</span>
                <span className="text-gray-600">Ім'я:</span>
                <span>{userData.self.first_name}</span>
                {userData.self.last_name && (
                  <>
                    <span className="text-gray-600">Прізвище:</span>
                    <span>{userData.self.last_name}</span>
                  </>
                )}
                {userData.self.username && (
                  <>
                    <span className="text-gray-600">Username:</span>
                    <span>@{userData.self.username}</span>
                  </>
                )}
                <span className="text-gray-600">Телефон:</span>
                <span>{userData.self.phone}</span>
              </div>
            </div>
          )}
        </div>

        {/* Статистика */}
        <div className="bg-white rounded-lg shadow p-4 mt-4">
          <div className="flex items-center mb-4">
            <Activity className="w-5 h-5 mr-2" />
            <h3 className="text-lg font-semibold">Статистика</h3>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <span className="text-gray-600">Контактів:</span>
            <span>{userData?.contacts?.length || 0}</span>
            <span className="text-gray-600">Заблоковано:</span>
            <span>{userData?.blocked_users?.length || 0}</span>
            <span className="text-gray-600">Спільних чатів:</span>
            <span>{userData?.common_chats?.length || 0}</span>
          </div>
        </div>
      </div>

      {/* Фото та медіа */}
      <div className="col-span-4">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center mb-4">
            <Camera className="w-5 h-5 mr-2" />
            <h3 className="text-lg font-semibold">Медіа</h3>
          </div>
          
          {userData?.profile_photos && (
            <div className="mb-4">
              <h4 className="font-medium mb-2">Фотографії профілю</h4>
              <div className="grid grid-cols-3 gap-4">
                {userData.profile_photos.result?.photos?.map((photo, index) => (
                  <img
                    key={index}
                    src={`data:image/jpeg;base64,${photo.sizes[0]?.bytes}`}
                    alt={`Profile ${index + 1}`}
                    className="w-full h-24 object-cover rounded"
                  />
                ))}
              </div>
            </div>
          )}

          {userData?.saved_media_counts && (
            <div>
              <h4 className="font-medium mb-2">Медіа статистика</h4>
              {userData.saved_media_counts.map((counter, index) => (
                <div key={index} className="flex justify-between items-center py-1">
                  <span>{counter.filter._}</span>
                  <span className="font-medium">{counter.count}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Налаштування та безпека */}
      <div className="col-span-4">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center mb-4">
            <Shield className="w-5 h-5 mr-2" />
            <h3 className="text-lg font-semibold">Безпека</h3>
          </div>

          {/* Активні сесії */}
          {userData?.active_sessions?.result?.authorizations && (
            <div className="mb-4">
              <h4 className="font-medium mb-2">Активні сесії</h4>
              {userData.active_sessions.result.authorizations.map((session, index) => (
                <div key={index} className="p-2 bg-gray-50 rounded mb-2">
                  <div className="text-sm">{session.app_name}</div>
                  <div className="text-xs text-gray-500">{session.device_model} - {session.platform}</div>
                  <div className="text-xs text-gray-500">{session.country}</div>
                </div>
              ))}
            </div>
          )}

          {/* Налаштування приватності */}
          {userData?.privacy_settings && (
            <div>
              <h4 className="font-medium mb-2">Приватність</h4>
              <div className="space-y-2">
                {Object.entries(userData.privacy_settings).map(([key, value]) => (
                  <div key={key} className="flex justify-between items-center">
                    <span className="text-gray-600">{key}:</span>
                    <span>{JSON.stringify(value)}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDataViewer;