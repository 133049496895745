// src/constants/botConstructorHints.ts

// Інтерфейси
export interface CommandExample {
  response: string;
  description: string;
  handler_name?: string;
}

export interface HandlerExample {
  name: string;
  description: string;
  code: string;
}

// Тип для всіх обробників
export type HandlersExamples = Record<string, HandlerExample>;

// Тип для всіх команд
export type CommandsExamples = Record<string, CommandExample>;

// Приклади команд
export const commandsExample: Record<string, CommandExample> = {
  "/start": {
    response: [
      "👋 Вітаю! Я ваш особистий помічник бот.",
      "Ось що я вмію:",
      "/help - показати всі команди",
      "/register - зареєструватися",
      "/profile - переглянути профіль",
    ].join("\n"),
    description: "Привітальне повідомлення та основні команди",
    handler_name: "StartCommandHandler",
  },
  "/register": {
    response: [
      "📝 Для реєстрації, будь ласка, слідуйте інструкціям:",
      "1. Введіть ваше ім'я",
      "2. Введіть номер телефону",
      "3. Підтвердьте дані",
    ].join("\n"),
    description: "Початок процесу реєстрації",
    handler_name: "RegistrationHandler",
  },
  "/auth": {
    response: "Привіт",
    description: "Команда для початку процесу авторизації",
    handler_name: "AuthHandler",
  },
};

// Приклади обробників
export const handlersExamples: Record<string, HandlerExample> = {
  StartCommandHandler: {
    name: "StartCommandHandler",
    description: "Обробник команди /start",
    code: `public function handle($message)
  {
      $chatId = $message['chat']['id'];
      $firstName = $message['from']['first_name'] ?? '';
      
      $welcomeMessage = "Вітаю, {$firstName}! 👋\\n\\n" .
          "Я ваш особистий помічник бот.\\n" .
          "Ось що я вмію:\\n" .
          "/help - показати всі команди\\n" .
          "/register - зареєструватися\\n" .
          "/profile - переглянути профіль";
      
      return $this->sendMessage($welcomeMessage, $this->getMainKeyboard());
  }`,
  },
  RegistrationHandler: {
    name: "RegistrationHandler",
    description: "Обробник для реєстрації користувачів з підтримкою станів",
    code: `public function handle()
{
    $state = $this->getCurrentState();
    $text = $this->getText();
    
    switch($state) {
        case 'START':
            if ($text === '/register') { 
                $this->setState('AWAITING_NAME');
                return $this->sendMessage("👤 Будь ласка, введіть ваше ім'я:");
            }
            break;
        
        case 'AWAITING_NAME':
            if (strlen($text) < 2) {
                return $this->sendMessage("❌ Ім'я занадто коротке. Спробуйте ще раз:");
            }
            
            $this->setUserData('name', $text);
            $this->setState('AWAITING_PHONE');
            
            return $this->sendMessage("📱 Тепер введіть ваш номер телефону у форматі +380XXXXXXXXX:");
            
        case 'AWAITING_PHONE':
            if (!preg_match('/^\+380\d{9}$/', $text)) {
                return $this->sendMessage("❌ Неправильний формат номера. Спробуйте ще раз:");
            }
            
            $this->setUserData('phone', $text);
            $this->setState('CONFIRMATION');
            
            $userData = $this->getUserData();
            return $this->sendMessage(
                "✅ Перевірте ваші дані:\n" .
                "Ім'я: {$userData['name']}\n" .
                "Телефон: {$userData['phone']}\n\n" .
                "Все правильно? Напишіть 'так' для підтвердження або 'ні' для скасування"
            );
            
        case 'CONFIRMATION':
            if (strtolower($text) === 'так') {
                $this->setState('START');
                return $this->sendMessage(
                    "🎉 Вітаємо! Реєстрацію завершено!\n" .
                    "Тепер ви можете користуватися всіма функціями бота.",
                    $this->getMainKeyboard()
                );
            } elseif (strtolower($text) === 'ні') {
                // Очищаємо стан при відміні реєстрації
                $this->clearState();
                $this->setState('START');
                return $this->sendMessage(
                    "🔄 Реєстрацію скасовано. Спробуйте ще раз використавши команду /register"
                );
            } else {
                return $this->sendMessage(
                    "❓ Напишіть 'так' для підтвердження або 'ні' для скасування"
                );
            }
    }
    
    return $this->sendMessage("Для початку реєстрації використайте команду /register");
}`,
  },
  GetContactHandler: {
    name: "InlineKeyboardHandler",
    description: "Обробник для inline клавіатури",
    code: `public function handle($message)
{
    $chatId = $message['chat']['id'];
    
    // Отримуємо дані користувача з повідомлення
    $firstName = $message['from']['first_name'] ?? '';
    $lastName = $message['from']['last_name'] ?? '';
    $username = $message['from']['username'] ?? '';
    
    // Для телефону потрібно використовувати спеціальну кнопку
    $keyboard = [
        'keyboard' => [
            [
                ['text' => 'Поділитися контактом', 'request_contact' => true]
            ]
        ],
        'resize_keyboard' => true,
        'one_time_keyboard' => true
    ];

    // Перевіряємо чи прийшов контакт
    if (isset($message['contact'])) {
        $phone = $message['contact']['phone_number'];
        return $this->sendMessage("Дякую за надання контакту! Ваш номер: $phone");
    }

    // Формуємо привітання використовуючи доступні дані
    $fullName = trim("$firstName $lastName");
    $welcomeMessage = "Вітаю, {$fullName}! 👋\n\n" .
        "Я ваш особистий помічник бот.\n" .
        "Ось що я вмію:\n" .
        "/help - показати всі команди\n";

    if ($username) {
        $welcomeMessage .= "\nВаш username: @$username";
    }

    return $this->sendMessage($welcomeMessage, $keyboard);
}`,
  },
  AuthHandler: {
    name: "AuthHandler",
    description: "Обробник для авторизації користувачів через Telegram",
    code: `public function handle($message)
{
   $text = $this->getText();
   $contact = $this->getContact();
   $firstName = $this->getFirstName();
   $lastName = $this->getLastName();
   $username = $this->getUsername();
   $chatId = $this->getChatId();
   
   $currentState = $this->getCurrentState();
   // Визначаємо клавіатуру для введення коду
   $numericKeyboard = [
        'keyboard' => [
            [['text' => '1'], ['text' => '2'], ['text' => '3']],
            [['text' => '4'], ['text' => '5'], ['text' => '6']],
            [['text' => '7'], ['text' => '8'], ['text' => '9']],
            [['text' => '❌ Очистити'], ['text' => '0']]
        ],
        'resize_keyboard' => true 
    ];
    $encodedNumericKeyboard = json_encode($numericKeyboard);

   if ($text === '/auth') {
       $greeting = "👋 Вітаю, {$firstName}" . ($lastName ? " {$lastName}" : "") . "!\n";
       $greeting .= "Починаємо процес авторизації. ";
       $greeting .= "Для продовження вам потрібно буде поділитися своїм контактом.";
       
       if ($username) {
           $greeting .= "    Ваш username: @{$username}";
       }
       
       $keyboard = [
           'keyboard' => [
               [['text' => '📱 Поділитися контактом', 'request_contact' => true]]
           ],
           'resize_keyboard' => true,
           'one_time_keyboard' => true
       ];
       
       $this->setState('waiting_contact');
       return $this->sendMessage($greeting, json_encode($keyboard));
   }
   
   switch ($currentState) {
       case 'waiting_contact':
       if (!$contact) {
           $keyboard = [
               'keyboard' => [
                   [['text' => '📱 Поділитися контактом', 'request_contact' => true]]
               ],
               'resize_keyboard' => true,
               'one_time_keyboard' => true
           ];
           return $this->sendMessage(
               'Будь ласка, використовуйте кнопку "Поділитися контактом"',
               json_encode($keyboard)
           );
       }

       Log::info('Contact received:', ['contact' => $contact]);
       $phoneNumber = '+' . ltrim($contact['phone_number'], '+');  

       $authResult = $this->startAuth($phoneNumber);
       Log::info('Auth result:', ['result' => $authResult]);

       if ($authResult['requiresCode']) { 

           $this->setState('waiting_code');
           $this->setUserData('auth_data', [
               'phone_number' => $phoneNumber,
               'phone_code_hash' => $authResult['phoneCodeHash']
           ]);
           
           // Встановлюємо початковий стан для коду верифікації
           $this->setUserData('verification_code', '');
           return $this->sendMessage(
               'Введіть код підтвердження, який ви отримали в Telegram',
               $encodedNumericKeyboard
           );
       }
       return $this->sendMessage('Помилка авторизації. Спробуйте ще раз.');

       case 'waiting_code':
         Log::info('Input text:', ['text' => $text, 'type' => gettype($text), 'length' => strlen($text)]);

    if ($text === '❌ Очистити') {
        $this->setUserData('verification_code', '');
        return $this->sendMessage('Код очищено. Введіть код знову.', $encodedNumericKeyboard);
    }

    $authData = $this->getUserData();
    
    // Якщо це перший вхід (код ще не почали вводити)
    if (!isset($authData['verification_code'])) {
        $this->setUserData('verification_code', '');
        return $this->sendMessage('Введіть код підтвердження по одній цифрі', $encodedNumericKeyboard);
    }

    // Перевіряємо що введена цифра
      if (!in_array($text, ['0','1','2','3','4','5','6','7','8','9'])) {
        return $this->sendMessage('Будь ласка, використовуйте клавіатуру для введення коду', $encodedNumericKeyboard);
    }

    $currentCode = $authData['verification_code'];
    $newCode = $currentCode . $text;

    if (strlen($newCode) < 5) {
        $this->setUserData('verification_code', $newCode);
        return $this->sendMessage(
            "Введено: $newCode\nЩе " . (5 - strlen($newCode)) . " цифр",
            $encodedNumericKeyboard
        );
    }

    if (strlen($newCode) === 5) {
        $completeResult = $this->completeAuth(
            $authData['auth_data']['phone_number'],
            $newCode,
            null
        );
        Log::info('completeResult:', ['result' => $completeResult]);

        if ($completeResult['isAuthorized']) {
            $this->setState('authorized');
            return $this->sendMessage(
                'Авторизація успішна! Тепер ви можете користуватися всіма функціями.',
                json_encode(['remove_keyboard' => true])
            );
        } elseif ($completeResult['requires2FA']) {
            $this->setState('waiting_2fa');
            return $this->sendMessage('Введіть пароль двофакторної автентифікації:', $encodedNumericKeyboard);
        }
        
        $this->setUserData('verification_code', '');
        return $this->sendMessage('Невірний код. Спробуйте ще раз.', $encodedNumericKeyboard);
    }

       case 'waiting_2fa':
           if (!$text) {
               return $this->sendMessage('Введіть пароль двофакторної автентифікації');
           }
           
           $authData = $this->getUserData();
           $completeResult = $this->completeAuth(
               $authData['auth_data']['phone_number'],
               null,
               $text
           );
           
           if ($completeResult['isAuthorized']) {
               $this->setState('authorized');
               return $this->sendMessage('Авторизація успішна! Тепер ви можете користуватися всіма функціями.');
           }
           return $this->sendMessage('Невірний пароль. Спробуйте ще раз.');

       case 'authorized':
           return $this->sendMessage('Ви вже авторизовані. Використовуйте /status для перевірки статусу.');
           
       default:
           return $this->sendMessage('Використовуйте команду /auth для початку авторизації');
   }
}`,
  },
  InlineKeyboardHandler: {
    name: "InlineKeyboardHandler",
    description: "Обробник для inline клавіатури",
    code: `public function handle($message)
{
    
    $chatId = $this->getChatId();
    $callbackData = isset($message['callback_query']) ? $message['callback_query']['data'] : null;
    
    if ($callbackData) {
        switch($callbackData) {
            case 'payment_123':
                return $this->sendMessage("💳 Оплата...\nОберіть спосіб оплати:");
            case 'cart_view':
                return $this->sendMessage("🛒 Ваш кошик порожній");
            case 'orders_list':
                return $this->sendMessage("📦 Список ваших замовлень порожній");
            default:
                return $this->sendMessage("⚠️ Невідома команда");
        }
    }
    
    return $this->sendMessage("Оберіть опцію:", $this->getMainKeyboard());
}`,
  },
  ProductActionsHandler: {
    name: "ProductActionsHandler",
    description: "Обробник для дій з товаром",
    code: `public function handle($message)
{
    
    $chatId = $this->getChatId();
    $callbackData = isset($message['callback_query']) ? $message['callback_query']['data'] : null;
    
    if ($callbackData) {
        switch($callbackData) {
            case 'add_to_cart':
                return $this->sendMessage("✅ Товар додано до кошика!");
            case 'add_to_favorites':
                return $this->sendMessage("❤️ Товар додано до обраного!");
            case 'show_details':
                return $this->sendMessage("🔍 Деталі товару:\nНазва: Товар\nЦіна: 100 грн\nОпис: ...");
            default:
                return $this->sendMessage("⚠️ Невідома команда");
        }
    }
    
    return $this->sendMessage("Оберіть дію:", $this->getMainKeyboard());
}`,
  },
};

// Приклади клавіатур
export const keyboardExamples = {
  reply: {
    type: "reply",
    buttons: [
      [{ text: "👤 Мій профіль" }, { text: "⚙️ Налаштування" }],
      [{ text: "📞 Контакти" }, { text: "❓ Допомога" }],
      [{ text: "📱 Поділитися номером", request_contact: true }],
    ],
  },
  inline: {
    type: "inline",
    buttons: [
      [
        {
          text: "💳 Оплатити",
          callback_data: "payment_123",
        },
        {
          text: "🛒 Кошик",
          callback_data: "cart_view",
        },
      ],
      [
        {
          text: "📦 Мої замовлення",
          callback_data: "orders_list",
        },
      ],
    ],
  },
  product_actions: {
    type: "inline",
    buttons: [
      [
        {
          text: "➕ Додати в кошик",
          callback_data: "add_to_cart",
        },
      ],
      [
        {
          text: "❤️ В обране",
          callback_data: "add_to_favorites",
        },
        {
          text: "🔍 Деталі",
          callback_data: "show_details",
        },
      ],
    ],
  },
  pagination: {
    type: "inline",
    buttons: [
      [
        {
          text: "⬅️ Назад",
          callback_data: "prev_page",
        },
        {
          text: "➡️ Вперед",
          callback_data: "next_page",
        },
      ],
    ],
  },
};

export const callbackHandlerExamples = {
  CartHandler: {
    name: "CartHandler",
    description: "Обробник для роботи з кошиком",
    code: `public function handle($message) {
    $chatId = $message['chat']['id'];
    $callback = $message['callback_query']['data'] ?? '';

    switch($callback) {
        case 'cart_view':
            return $this->sendMessage("🛒 Ваш кошик:\n1. Товар 1 - 100грн\n2. Товар 2 - 200грн\n\nВсього: 300грн");
        case 'add_to_cart':
            return $this->sendMessage("✅ Товар додано до кошика!");
        default:
            return $this->sendMessage("❌ Невідома команда");
    }
}`,
  },
  PaginationHandler: {
    name: "PaginationHandler",
    description: "Обробник для пагінації",
    code: `public function handle($message) {
    $chatId = $message['chat']['id'];
    $callback = $message['callback_query']['data'] ?? '';
    
    $page = isset($_SESSION['page']) ? $_SESSION['page'] : 1;
    
    if ($callback === 'next_page') {
        $page++;
    } elseif ($callback === 'prev_page') {
        $page = max(1, $page - 1);
    }
    
    $_SESSION['page'] = $page;
    
    return $this->sendMessage(
        "📄 Сторінка {$page}",
        $this->getMainKeyboard()
    );
}`,
  },
};

// Рекомендації
export const keyboardRecommendations = [
  "Використовуйте emoji для кращої візуалізації кнопок",
  "Групуйте пов'язані кнопки в один ряд",
  "Для inline кнопок завжди додавайте унікальні callback_data",
  "Не робіть занадто довгі тексти на кнопках",
  "Оптимальна кількість кнопок в ряду - 2-3",
];

// Функція для отримання обробника за командою
export const getHandlerForCommand = (
  command: string
): HandlerExample | null => {
  const handlerName = commandsExample[command]?.handler_name;
  return handlerName ? handlersExamples[handlerName] : null;
};

export const menuExamples = {
  "Стандартне меню": {
    items: [
      {
        text: "📱 Послуги",
        submenu: [
          [{ text: "💻 Веб-розробка" }, { text: "📱 Мобільні додатки" }],
          [{ text: "🤖 Боти" }, { text: "🎨 Дизайн" }],
        ],
      },
      {
        text: "👥 Про нас",
        response: "Ми команда професіоналів з багаторічним досвідом розробки",
      },
      {
        text: "📞 Контакти",
        response: "Телефон: +380123456789\nEmail: info@example.com",
      },
    ],
  },
  Магазин: {
    items: [
      {
        text: "🛍️ Каталог",
        submenu: [
          [{ text: "👕 Одяг" }, { text: "👟 Взуття" }],
          [{ text: "🎒 Аксесуари" }],
        ],
      },
      {
        text: "🛒 Кошик",
        response: "Ваш кошик порожній",
      },
    ],
  },
  "Меню з обробником": {
    items: [
      {
        text: "📊 Статистика",
        handler_name: "StatsHandler", // Буде виконано цей обробник
      },
      {
        text: "👥 Користувачі",
        handler_name: "UsersHandler",
      },
    ],
  },
};

export const getMenuExample = (name: string) => menuExamples[name];
