// components/TelegramAuth/CodeStep.tsx
import React, { useState, useRef } from 'react';
import styles from '../style/TelegramAuth.module.css';

interface CodeStepProps {
  onSubmit: (code: string) => Promise<void>;
  onBack: () => void;
  isLoading: boolean;
}

export const CodeStep: React.FC<CodeStepProps> = ({ onSubmit, onBack, isLoading }) => {
  const [code, setCode] = useState('');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleCodeChange = (index: number, value: string) => {
      if (value.length <= 1 && /^\d*$/.test(value)) {
          const newCode = code.split('');
          newCode[index] = value;
          const updatedCode = newCode.join('');
          setCode(updatedCode);

          // Автофокус на наступне поле
          if (value && index < 4) {
              inputRefs.current[index + 1]?.focus();
          }

          // Автоматична відправка коду при заповненні всіх полів
          if (updatedCode.length === 5) {
              handleSubmit(updatedCode);
          }
      }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && !code[index] && index > 0) {
          inputRefs.current[index - 1]?.focus();
      }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
      e.preventDefault();
      const pastedData = e.clipboardData.getData('text');
      const numbers = pastedData.replace(/\D/g, '').slice(0, 5);
      
      if (numbers.length) {
          setCode(numbers);
          handleSubmit(numbers);
      }
  };

  const handleSubmit = async (submittedCode: string) => {
      if (submittedCode.length === 5 && !isLoading) {
          try {
              await onSubmit(submittedCode);
          } catch (error) {
              // Помилка обробляється в батьківському компоненті
              // Очищаємо код при помилці
              setCode('');
              inputRefs.current[0]?.focus();
          }
      }
  };

  return (
      <div className={styles.stepContent}>
          <div className={styles.authHeader}>
              <h2 className={styles.authTitle}>Код підтвердження</h2>
              <p className={styles.authSubtitle}>
                  Введіть код, надісланий в офіційний додаток Telegram
              </p>
          </div>

          <div className={styles.codeInputGroup}>
              {[0, 1, 2, 3, 4].map((index) => (
                  <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      className={styles.codeInput}
                      maxLength={1}
                      value={code[index] || ''}
                      onChange={(e) => handleCodeChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      onPaste={index === 0 ? handlePaste : undefined}
                      disabled={isLoading}
                      autoFocus={index === 0}
                  />
              ))}
          </div>

          <div className={styles.buttonGroup}>
              <button
                  type="button"
                  className={styles.backButton}
                  onClick={onBack}
                  disabled={isLoading}
              >
                  Змінити номер
              </button>
          </div>

          {isLoading && (
              <div className={styles.loadingMessage}>
                  Перевірка коду...
              </div>
          )}
      </div>
  );
};