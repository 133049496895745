// components/TelegramAuth/TwoFactorStep.tsx
import React, { useState } from 'react';
import styles from '../style/TelegramAuth.module.css';

interface TwoFactorStepProps {
  onSubmit: (password: string) => Promise<void>;
  onBack: () => void;
  isLoading: boolean;
}

export const TwoFactorStep: React.FC<TwoFactorStepProps> = ({ 
  onSubmit, 
  onBack, 
  isLoading 
}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!password || isLoading) return;

      try {
          await onSubmit(password);
      } catch (error) {
          // Помилка обробляється в батьківському компоненті
          setPassword('');
      }
  };

  return (
      <form onSubmit={handleSubmit} className={styles.stepForm}>
          <div className={styles.authHeader}>
              <h2 className={styles.authTitle}>Двофакторна автентифікація</h2>
              <p className={styles.authSubtitle}>
                  Введіть пароль двофакторної автентифікації
              </p>
          </div>

          <div className={styles.formGroup}>
              <label className={styles.formLabel}>
                  Пароль 2FA
              </label>
              <div className={styles.passwordWrapper}>
                  <input
                      type={showPassword ? 'text' : 'password'}
                      className={styles.formInput}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isLoading}
                      required
                  />
                  <button
                      type="button"
                      className={styles.togglePassword}
                      onClick={() => setShowPassword(!showPassword)}
                      disabled={isLoading}
                  >
                      {showPassword ? 'Приховати' : 'Показати'}
                  </button>
              </div>
          </div>

          <div className={styles.buttonGroup}>
              <button
                  type="submit"
                  className={styles.submitButton}
                  disabled={isLoading || !password}
              >
                  {isLoading ? 'Перевірка...' : 'Підтвердити'}
              </button>
              
              <button
                  type="button"
                  className={styles.backButton}
                  onClick={onBack}
                  disabled={isLoading}
              >
                  Назад
              </button>
          </div>
      </form>
  );
};