import React, { useEffect, useState } from "react";
import { BotData } from "../types/telegram";

interface BotSettingsProps {
  bot: BotData;
  setBot: (bot: BotData) => void;
  onSave: (botData: BotData) => Promise<void>;
  setMessage: (message: string) => void;
}

const BotSettings: React.FC<BotSettingsProps> = ({
  bot,
  setBot,
  onSave,
  setMessage,
}) => {
  useEffect(() => {
    setSettings({
      name: bot?.name || "",
      bot_token: bot?.bot_token || "",
      description: bot?.description || "",
      welcome_message: bot?.welcome_message || "",
      error_message: bot?.error_message || "",
    });
  }, [bot]);

  const [settings, setSettings] = useState({
    name: bot?.name || "",
    bot_token: bot?.bot_token || "",
    description: bot?.description || "",
    welcome_message: bot?.welcome_message || "",
    error_message: bot?.error_message || "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSettings((prev) => {
      const newSettings = {
        ...prev,
        [name]: value,
      };

      // Оновлюємо локальний стан бота
      setBot({
        ...bot,
        ...newSettings,
      });

      return newSettings;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const updatedBot = {
        ...bot,
        ...settings,
      };

      await onSave(updatedBot);
      setBot(updatedBot);
    } catch (error) {
      setMessage(
        `Помилка збереження налаштувань: ${
          error instanceof Error ? error.message : "Невідома помилка"
        }`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block mb-1">Назва бота:</label>
        <input
          type="text"
          name="name"
          value={settings.name}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          placeholder="Введіть назву бота"
        />
      </div>

      <div>
        <label className="block mb-1">Токен бота:</label>
        <input
          type="text"
          name="bot_token"
          value={settings.bot_token}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          placeholder="Введіть токен бота"
        />
      </div>

      <div>
        <label className="block mb-1">Опис:</label>
        <textarea
          name="description"
          value={settings.description}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          rows={3}
          placeholder="Опис бота"
        />
      </div>

      <div>
        <label className="block mb-1">Привітальне повідомлення:</label>
        <textarea
          name="welcome_message"
          value={settings.welcome_message}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          rows={3}
          placeholder="Введіть привітальне повідомлення"
        />
      </div>

      <div>
        <label className="block mb-1">Повідомлення про помилку:</label>
        <textarea
          name="error_message"
          value={settings.error_message}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          rows={2}
          placeholder="Введіть повідомлення про помилку"
        />
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
      >
        {bot.id ? "Оновити налаштування" : "Створити бота"}
      </button>
    </form>
  );
};

export default BotSettings;
