import React, { useState } from 'react';

const HistoryLoaderDialog = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  channelTitle,
  isLoading 
}) => {
  const [messageCount, setMessageCount] = useState(100);
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleConfirm = () => {
    if (messageCount < 1) {
      setError('Кількість повідомлень має бути більше 0');
      return;
    }
    if (messageCount > 1000) {
      setError('Максимальна кількість повідомлень - 1000');
      return;
    }
    onConfirm(messageCount);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />
      
      {/* Dialog */}
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md m-4 p-6">
        {/* Header */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold">
            Завантаження історії повідомлень
          </h3>
          <p className="text-sm text-gray-500">
            Завантаження історії для каналу: {channelTitle}
          </p>
        </div>

        {/* Content */}
        <div className="space-y-4">
          <div>
            <label 
              htmlFor="messageCount" 
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Кількість повідомлень
            </label>
            <input
              id="messageCount"
              type="number"
              value={messageCount}
              onChange={(e) => {
                setMessageCount(Number(e.target.value));
                setError('');
              }}
              min={1}
              max={1000}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
              {error}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Скасувати
          </button>
          <button
            onClick={handleConfirm}
            disabled={isLoading}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-gray-300"
          >
            {isLoading ? 'Завантаження...' : 'Завантажити'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoryLoaderDialog;