import { useState, useEffect } from "react";
import axios from "axios";

const MediaViewer = ({ message, bot, selectedState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null); // Додаємо стан для збереження URL медіа
  const [mediaType, setMediaType] = useState(null); // Додаємо стан для типу медіа
  const apiUrl = process.env.REACT_APP_API_URL;

  const getMediaUrl = (url) => `${apiUrl}/${url}`;

  useEffect(() => {
    // Якщо медіа вже завантажене, одразу відобразити його
    if (message.media && message.media.length > 0) {
      const mediaItem = message.media[0];
      console.log("Initial media item:", mediaItem);

      if (mediaItem.media_type === 'photo') {
        setMediaUrl(getMediaUrl(mediaItem.url));
        setMediaType('photo');
        console.log("Set mediaUrl for photo:", mediaUrl);
      } else if (mediaItem.media_type === 'document' && mediaItem.mime_type?.includes('video')) {
        setMediaUrl(getMediaUrl(mediaItem.url));
        setMediaType('video');
        console.log("Set mediaUrl for video:", mediaUrl);
      } else if (mediaItem.media_type === 'messageMediaWebPage') {
        console.error("Unsupported media type: WebPage");
        // Можна відобразити повідомлення чи обробити це по-іншому
        setMediaType('unsupported');
      }
    }
  }, [message.media]); // Відслідковуємо зміни в медіа

  const downloadMedia = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/download-media`,
        {
          user_id: selectedState.user_id,
          message_media: message.additional_info.media,
          peer: message.chat_id,
          message_id: message.message_id
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        const { url, mime_type } = response.data.result;
        console.log("Downloaded media URL:", url);

        // Перевіряємо, чи існує URL
        if (url) {
          setMediaUrl(url); // Використовуємо getMediaUrl для формування URL медіа

          // Визначаємо тип медіа на основі mime_type
          if (mime_type?.includes('image')) {
            setMediaType('photo');
            console.log("Media type set to photo.");
          } else if (mime_type?.includes('video')) {
            setMediaType('video');
            console.log("Media type set to video.");
          } else if (mime_type?.includes('pdf')) {
            setMediaType('document');
            console.log("Media type set to document.");
          } else {
            setMediaType('unsupported');
            console.log("Unsupported media type");
          }
        } else {
          console.error('No URL found in response');
        }
      } else {
        console.error('Download failed, no success flag');
      }
    } catch (error) {
      console.error('Error downloading media:', error);
    } finally {
      setIsLoading(false);
    }
  }; 
  // Якщо є незавантажене медіа, відображаємо кнопку для завантаження
  const originalMedia = message.additional_info?.media;
  console.log("Original media:", originalMedia);

  if (originalMedia && originalMedia._ && !mediaUrl) {
    return (
      <button
        onClick={downloadMedia}
        disabled={isLoading}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
      >
        {isLoading ? "Завантаження..." : "Завантажити медіа"}
      </button>
    );
  }

  // Відображаємо медіа після завантаження в залежності від типу
  if (mediaUrl) {
    console.log("Displaying media with URL:", mediaUrl);
    if (mediaType === 'photo') {
      return <img src={mediaUrl} alt="Media content" className="max-w-full h-auto" />;
    }

    if (mediaType === 'video') {
      return (
        <video controls className="max-w-full">
          <source src={mediaUrl} type="video/mp4" />
        </video>
      );
    }

    if (mediaType === 'document') {
      return (
        <div>
          <embed 
            src={mediaUrl} 
            type="application/pdf" 
            width="100%" 
            height="600px" 
          />
        </div>
      );
    }

    if (mediaType === 'unsupported') {
      return <p>Цей тип медіа не підтримується.</p>;
    }
  }

  return null;
};

export default MediaViewer;