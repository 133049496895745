import { Play } from 'lucide-react';

const MediaContent = ({ media }) => {
  if (!media) return null;

  // Для відео документів
  if (media.document?.thumbs?.[0]?.inflated?.bytes) {
    const { document } = media;
    const previewData = `data:image/jpeg;base64,${document.thumbs[0].inflated.bytes}`;
    const videoAttr = document.attributes?.find(attr => attr._ === 'documentAttributeVideo');

    return (
      <div className="relative mb-4 group cursor-pointer">
        <img 
          src={previewData} 
          alt="Video preview"
          className="w-full rounded-lg object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="flex items-center gap-2 text-white bg-black bg-opacity-50 px-3 py-1 rounded-full">
            <Play size={20} />
            {formatDuration(videoAttr?.duration)}
          </div>
        </div>
        {/* Розміри відео та розмір файлу */}
        <div className="absolute bottom-2 right-2 text-xs text-white bg-black bg-opacity-50 px-2 py-1 rounded">
          {videoAttr?.w}x{videoAttr?.h} • {formatSize(document.size)}
        </div>
      </div>
    );
  }

  // Для фото
  if (media.photo?.sizes) {
    // Шукаємо превью в форматі base64
    const strippedThumb = media.photo.sizes.find(size => size._ === 'photoStrippedSize');
    if (strippedThumb?.inflated?.bytes) {
      return (
        <div className="mb-4">
          <img 
            src={`data:image/jpeg;base64,${strippedThumb.inflated.bytes}`}
            alt="Photo"
            className="w-full rounded-lg object-cover"
          />
        </div>
      );
    }
  }

  return null;
};

const formatDuration = (seconds) => {
  if (!seconds) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const formatSize = (bytes) => {
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(1)} KB`;
  }
  return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
};

export default MediaContent;