import { useState, useEffect } from "react";
import axios from "axios";

const JoinRequestsManagement = ({ projectId, setMessage }) => {
  const [joinRequests, setJoinRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState("pending");
  const [channelInfo, setChannelInfo] = useState(null);
  const [channelUsername, setChannelUsername] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (projectId) {
      fetchJoinRequests();
    }
  }, [projectId, statusFilter]);

  const fetchJoinRequests = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/join-requests`, {
        params: { project_id: projectId, status: statusFilter },
        headers: { Authorization: `Bearer ${token}` },
      });
      setJoinRequests(response.data);
    } catch (error) {
      console.error("Error fetching join requests:", error);
      setMessage("Error fetching join requests");
    }
  };

  const handleJoinRequest = async (requestId, action) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/join-requests/${requestId}/${action}`,
        { project_id: projectId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage(`Join request ${action === 'approve' ? 'approved' : 'rejected'} successfully`);
      fetchJoinRequests();
    } catch (error) {
      console.error(`Error ${action}ing join request:`, error);
      setMessage(`Error ${action}ing join request`);
    }
  };

  const fetchChannelInfo = async () => {
    if (!channelUsername) {
      setMessage("Please enter a channel username");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/channel-info`, {
        params: { project_id: projectId, channel_username: channelUsername },
        headers: { Authorization: `Bearer ${token}` },
      });
      setChannelInfo(response.data);
    } catch (error) {
      console.error("Error fetching channel info:", error);
      setMessage("Error fetching channel info");
    }
  };

  const getStatusBadge = (status) => {
    const statusColors = {
      pending: "bg-yellow-200 text-yellow-800",
      approved: "bg-green-200 text-green-800",
      rejected: "bg-red-200 text-red-800"
    };
    return (
      <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusColors[status]}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">Channel Information and Join Requests</h2>
      
      {/* Channel Info Section */}
      <div className="mb-4">
        <input
          type="text"
          value={channelUsername}
          onChange={(e) => setChannelUsername(e.target.value)}
          placeholder="Enter channel username"
          className="p-2 border rounded mr-2"
        />
        <button
          onClick={fetchChannelInfo}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Get Channel Info
        </button>
      </div>

      {channelInfo && (
        <div className="mb-4 p-4 border rounded">
          <h3 className="font-semibold">Channel Information</h3>
          <p>Title: {channelInfo.channel_info.title}</p>
          <p>Type: {channelInfo.channel_info.type}</p>
          <p>Member Count: {channelInfo.channel_info.member_count || 'N/A'}</p>
          <p>Pending Requests: {channelInfo.pending_requests}</p>
        </div>
      )}

      {/* Join Requests Section */}
      <h3 className="text-lg font-semibold mb-2">Join Requests</h3>
      <div className="mb-4">
        <label className="mr-2">Filter by status:</label>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
      {joinRequests.length === 0 ? (
        <p>No join requests found.</p>
      ) : (
        <ul className="space-y-4">
          {joinRequests.map((request) => (
            <li key={request.id} className="p-4 border rounded shadow-sm">
              <div className="flex justify-between items-center mb-2">
                <span>User ID: {request.user_id}</span>
                {getStatusBadge(request.status)}
              </div>
              <p>Chat ID: {request.chat_id}</p>
              <p>Requested at: {new Date(request.created_at).toLocaleString()}</p>
              {request.status === 'pending' && (
                <div className="mt-2">
                  <button
                    onClick={() => handleJoinRequest(request.id, 'approve')}
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2 hover:bg-green-600"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleJoinRequest(request.id, 'reject')}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Reject
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default JoinRequestsManagement;