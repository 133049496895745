import { useState, useEffect } from "react";
import axios from "axios";
import { Video, Image } from "lucide-react";
import HistoryLoaderDialog from "./HistoryLoaderDialog";
import MediaViewer from "./MediaViewer";

const MessagesViewer = ({ bot, selectedState, setMessage }) => {
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messagesPagination, setMessagesPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 50,
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [selectedLoadingChannel, setSelectedLoadingChannel] = useState(null);
  useEffect(() => {
    if (selectedState && selectedChannel) {
      fetchMessages(selectedChannel.peer_id);
    }
  }, [selectedState]);

  useEffect(() => {
    fetchChannels();
  }, [bot?.id]);

  // Функція завантаження історії
  const loadChannelHistory = async (channelId, messageLimit) => {
    if (!bot?.id || !selectedState) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/messages`,
        {
          user_id: selectedState.user_id,
          peer: channelId,
          offset_id: 0,
          limit: messageLimit,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setMessage("Історію повідомлень успішно завантажено");
        // Після завантаження оновлюємо список повідомлень
        await fetchMessages(channelId);
      } else {
        setMessage("Помилка при завантаженні історії");
      }
    } catch (error) {
      console.error("Error loading history:", error);
      setMessage(error.response?.data?.error || "Помилка завантаження історії");
    } finally {
      setLoading(false);
      setIsHistoryDialogOpen(false);
    }
  };

  const fetchChannels = async () => {
    if (!bot?.id) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/channels?user_id=${selectedState.user_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setChannels(response.data.data || []);
    } catch (error) {
      console.error("Error fetching channels:", error);
      setMessage("Помилка завантаження каналів");
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (channelId, page = 1) => {
    if (!bot?.id || !channelId) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/messages/channel/${channelId}`,
        {
          params: {
            page,
            limit: messagesPagination.limit,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const responseData = response.data.data;
      if (responseData && Array.isArray(responseData.data)) {
        setMessages(responseData.data);
        setMessagesPagination({
          currentPage: responseData.current_page,
          totalPages: responseData.last_page,
          limit: responseData.per_page,
        });
      } else {
        setMessages([]);
        console.error("Unexpected messages data structure:", responseData);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessage("Помилка завантаження повідомлень");
    } finally {
      setLoading(false);
    }
  };

  const getChannelType = (channel) => {
    if (!channel.additional_info) return "Невідомий тип";
    const info = channel.additional_info;

    if (info._ === "dialog") return "Діалог";
    if (info._ === "channel") {
      if (info.megagroup) return "Група";
      if (info.broadcast) return "Канал";
      return "Чат";
    }
    return "Невідомий тип";
  };

  const getChannelStatus = (channel) => {
    const statuses = [];
    const info = channel.additional_info || {};

    if (info.left) statuses.push("Покинуто");
    if (info.verified) statuses.push("Верифіковано");
    if (info.restricted) statuses.push("Обмежено");
    if (info.signatures) statuses.push("З підписами");
    if (info.slowmode_enabled) statuses.push("Повільний режим");

    return statuses.join(", ") || "Активний";
  };

  const getChannelTitle = (channel) => {
    if (channel.title) return channel.title;
    if (channel.additional_info?.title) return channel.additional_info.title;
    return `Чат ${channel.peer_id}`;
  };

  const MessageItem = ({ message, bot, selectedState }) => {
    const parsedMedia = (() => {
      try {
        return typeof message.additional_info === "string"
          ? JSON.parse(message.additional_info).media
          : message.additional_info?.media;
      } catch (e) {
        console.error("Error parsing media:", e);
        return null;
      }
    })();

    const parsedReactions = (() => {
      try {
        return typeof message.reactions === "string"
          ? JSON.parse(message.reactions)
          : message.reactions;
      } catch (e) {
        console.error("Error parsing reactions:", e);
        return null;
      }
    })();

    // Визначаємо тип медіа
    const mediaType = parsedMedia?._?.toLowerCase();
    const hasDocument = mediaType?.includes("document");
    const hasPhoto = mediaType?.includes("photo");
    const hasVideo =
      hasDocument &&
      parsedMedia?.document?.attributes?.some(
        (attr) => attr._ === "documentAttributeVideo"
      );

    return (
      <div className="p-4 border rounded-lg hover:bg-gray-50">
        <div className="flex justify-between items-start">
          <div className="space-y-2 flex-grow">
            {/* Медіа індикатори */}
            {(hasPhoto || hasVideo) && (
              <div className="flex gap-2 mb-2">
                {hasVideo && <Video className="w-5 h-5 text-blue-500" />}
                {hasPhoto && <Image className="w-5 h-5 text-green-500" />}
              </div>
            )}

            {/* Текст повідомлення */}
            <div className="text-gray-600">{message.message}</div>

            {/* Медіа контент */}
            {parsedMedia && (
              <MediaViewer
                message={message}
                bot={bot}
                selectedState={selectedState}
              />
            )}

            {/* Статистика */}
            <div className="flex flex-wrap gap-2 text-sm text-gray-500">
              {message.views > 0 && (
                <span className="bg-gray-100 px-2 py-1 rounded">
                  👁 {message.views}
                </span>
              )}
              {message.forwards > 0 && (
                <span className="bg-gray-100 px-2 py-1 rounded">
                  🔄 {message.forwards}
                </span>
              )}
              {message.is_channel_post && (
                <span className="bg-blue-100 px-2 py-1 rounded">📢 Пост</span>
              )}
            </div>

            {/* Реакції */}
            {parsedReactions?.results && (
              <div className="flex flex-wrap gap-2 text-sm">
                {parsedReactions.results.map((reaction, idx) => (
                  <span key={idx} className="bg-blue-50 px-2 py-1 rounded">
                    {reaction.reaction.emoticon} {reaction.count}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="text-sm text-gray-500 whitespace-nowrap ml-4">
            {new Date(message.message_date).toLocaleString()}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-12 gap-4 mt-4">
      {/* Список каналів */}
      <div className="col-span-4">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">Канали та діалоги</h3>
          <div className="space-y-3">
            {channels.map((channel) => (
              <div
                key={channel.peer_id}
                className={`p-4 rounded-lg border cursor-pointer transition-colors ${
                  selectedChannel?.peer_id === channel.peer_id
                    ? "bg-blue-50 border-blue-200"
                    : "hover:bg-gray-50"
                }`}
                onClick={() => {
                  setSelectedChannel(channel);
                  fetchMessages(channel.peer_id);
                }}
              >
                <div className="font-medium mb-2">
                  {getChannelTitle(channel)}
                </div>

                {channel.username && (
                  <div className="text-sm text-blue-600 mb-2">
                    @{channel.username}
                  </div>
                )}

                <div className="grid gap-1 text-sm text-gray-600">
                  <div className="flex justify-between">
                    <span>Тип:</span>
                    <span className="font-medium">
                      {getChannelType(channel)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>ID:</span>
                    <span className="font-medium">{channel.peer_id}</span>
                  </div>
                  {channel.participants_count > 0 && (
                    <div className="flex justify-between">
                      <span>Учасників:</span>
                      <span className="font-medium">
                        {channel.participants_count}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span>Статус:</span>
                    <span className="font-medium">
                      {getChannelStatus(channel)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Непрочитано:</span>
                    <span className="font-medium">{channel.unread_count}</span>
                  </div>
                  {channel.unread_mentions_count > 0 && (
                    <div className="flex justify-between">
                      <span>Згадування:</span>
                      <span className="font-medium">
                        {channel.unread_mentions_count}
                      </span>
                    </div>
                  )}
                  {channel.unread_reactions_count > 0 && (
                    <div className="flex justify-between">
                      <span>Реакції:</span>
                      <span className="font-medium">
                        {channel.unread_reactions_count}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span>Обробка:</span>
                    <span className="font-medium">
                      {channel.processing_status || "Невідомо"}
                    </span>
                  </div>
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedLoadingChannel(channel);
                      setIsHistoryDialogOpen(true);
                    }}
                    className="px-3 py-1 text-sm text-white bg-green-500 rounded hover:bg-green-600 transition-colors"
                  >
                    Завантажити історію
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Повідомлення */}
      <div className="col-span-8">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">
            {selectedChannel
              ? `Повідомлення: ${getChannelTitle(selectedChannel)}`
              : "Виберіть канал для перегляду повідомлень"}
          </h3>

          {loading ? (
            <div className="flex justify-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : selectedChannel ? (
            <>
              <div className="space-y-4">
                {Array.isArray(messages) && messages.length > 0 ? (
                  messages.map((message) => (
                    <MessageItem
                      key={message.id}
                      message={message}
                      bot={bot}
                      selectedState={selectedState}
                    />
                  ))
                ) : (
                  <div className="text-center text-gray-500 py-8">
                    Немає повідомлень для відображення
                  </div>
                )}
              </div>

              {messagesPagination.totalPages > 1 && (
                <div className="mt-4 flex justify-center space-x-2">
                  <button
                    onClick={() =>
                      fetchMessages(
                        selectedChannel.peer_id,
                        messagesPagination.currentPage - 1
                      )
                    }
                    disabled={messagesPagination.currentPage === 1}
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded disabled:bg-gray-300"
                  >
                    Попередня
                  </button>
                  <span className="px-4 py-2 text-sm">
                    Сторінка {messagesPagination.currentPage} з{" "}
                    {messagesPagination.totalPages}
                  </span>
                  <button
                    onClick={() =>
                      fetchMessages(
                        selectedChannel.peer_id,
                        messagesPagination.currentPage + 1
                      )
                    }
                    disabled={
                      messagesPagination.currentPage ===
                      messagesPagination.totalPages
                    }
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded disabled:bg-gray-300"
                  >
                    Наступна
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="text-center text-gray-500 py-8">
              Виберіть канал зі списку зліва для перегляду повідомлень
            </div>
          )}
        </div>
      </div>
      {selectedLoadingChannel && (
        <HistoryLoaderDialog
          isOpen={isHistoryDialogOpen}
          onClose={() => {
            setIsHistoryDialogOpen(false);
            setSelectedLoadingChannel(null);
          }}
          onConfirm={(messageCount) => {
            loadChannelHistory(selectedLoadingChannel.peer_id, messageCount);
          }}
          channelTitle={getChannelTitle(selectedLoadingChannel)}
          isLoading={loading}
        />
      )}
    </div>
  );
};

export default MessagesViewer;
