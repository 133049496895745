import ModalCustom from "../../partial/ModalCustom";
import { BotData } from "../types/telegram";
import { menuExamples } from "../constants/botConstructorHints";

interface MenuManagerProps {
  bot: BotData;
  setBot: (bot: BotData) => void;
  onSave: (botData: BotData) => Promise<void>;
  setMessage: (message: string) => void;
  setIsOpenMenuHint: (isOpen: boolean) => void;
  isOpenMenuHint: boolean;
  deleteMenuItem: (index: number) => Promise<void>;
}

const MenuManager: React.FC<MenuManagerProps> = ({
  bot,
  setBot,
  onSave,
  setMessage,
  isOpenMenuHint,
  setIsOpenMenuHint,
  deleteMenuItem,
}) => {
  const addMenuItem = () => {
    const newMenu = {
      items: [
        ...(bot.menu_structure?.items || []),
        { text: "", response: "", submenu: [] },
      ],
    };
    setBot({ ...bot, menu_structure: newMenu });
  };

  const addSubmenuItem = (itemIndex: number) => {
    const newItems = [...bot.menu_structure.items];
    newItems[itemIndex].submenu = [
      ...(newItems[itemIndex].submenu || []),
      [{ text: "" }],
    ];
    setBot({ ...bot, menu_structure: { items: newItems } });
  };

  const updateMenuItem = (index: number, field: string, value: string) => {
    const newItems = [...bot.menu_structure.items];
    newItems[index] = { ...newItems[index], [field]: value };
    setBot({ ...bot, menu_structure: { items: newItems } });
  };

  const handleSave = async () => {
    try {
      await onSave(bot);
      setMessage("Меню успішно збережено");
    } catch (error) {
      setMessage("Помилка збереження меню");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Налаштування меню</h3>

        <button
          onClick={() => setIsOpenMenuHint(true)}
          className="text-blue-500 hover:text-blue-600"
        >
          Приклади меню
        </button>
      </div>
      {bot.menu_structure?.items.map((item, index) => (
        <div key={index} className="relative space-y-2 p-4 border rounded">
          <div className="flex justify-between items-center">
            <input
              type="text"
              value={item.text}
              onChange={(e) => updateMenuItem(index, "text", e.target.value)}
              className="flex-1 p-2 border rounded"
              placeholder="Текст пункту меню"
            />
            <button
              onClick={() => deleteMenuItem(index)}
              className="ml-2 bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
            >
              Видалити
            </button>
          </div>
          <textarea
            value={item.response}
            onChange={(e) => updateMenuItem(index, "response", e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Відповідь"
          />
          <button
            onClick={() => addSubmenuItem(index)}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Додати підменю
          </button>
        </div>
      ))}
      <button
        onClick={addMenuItem}
        className="w-full bg-green-500 text-white py-2 rounded"
      >
        Додати пункт меню
      </button>
      <button
        onClick={handleSave}
        className="w-full bg-blue-500 text-white py-2 rounded"
      >
        Зберегти меню
      </button>
      {isOpenMenuHint && (
        <ModalCustom
          isOpen={isOpenMenuHint}
          closeModal={() => setIsOpenMenuHint(false)}
          text="Приклади меню"
        >
          <div className="p-4">
            {Object.entries(menuExamples).map(([name, example]) => (
              <div key={name} className="mb-6">
                <div className="flex justify-between items-center">
                  <h4 className="font-semibold">{name}</h4>
                  <button
                    onClick={() => {
                      setBot({ ...bot, menu_structure: example });
                      setIsOpenMenuHint(false);
                    }}
                    className="bg-blue-500 text-white px-3 py-1 rounded"
                  >
                    Використати
                  </button>
                </div>
                <pre className="mt-2 bg-gray-100 p-2 rounded">
                  {JSON.stringify(example, null, 2)}
                </pre>
              </div>
            ))}
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default MenuManager;
